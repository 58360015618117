import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { db, auth } from "../firebase";
import { collection, getDocs, doc, getDoc, setDoc } from "firebase/firestore";

const CoursesListPage = () => {
    const [courses, setCourses] = useState([]);
    const [userEnrollments, setUserEnrollments] = useState({});
    const navigate = useNavigate();
    const user = auth.currentUser;

    useEffect(() => {
        const fetchCourses = async () => {
            const querySnapshot = await getDocs(collection(db, "courses"));
            const coursesData = querySnapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));
            setCourses(coursesData);
        };

        const fetchUserEnrollments = async () => {
            if (!user) return;
        
            const userRef = doc(db, "userProgress", user.uid);
            const userSnap = await getDoc(userRef);
        
            if (!userSnap.exists()) {
                setUserEnrollments({});
                return;
            }
        
            const userData = userSnap.data();
            const enrolledCourses = userData.enrolledCourses || {}; // Ensure enrolledCourses exists
        
            const coursesRef = collection(db, "courses");
            const coursesSnap = await getDocs(coursesRef);
        
            const updatedEnrollments = {};
            
            coursesSnap.forEach((doc) => {
                const courseId = doc.id;
                const courseData = doc.data();
                console.log("COURSEDATA---",courseData)
                const totalModules = courseData.totalModules || 1; // Default to 1 to avoid division by zero
                
                if (enrolledCourses[courseId]) { // Only process enrolled courses
                    const completedModules = Object.values(userData.completedModules || {}).filter(Boolean).length;
                    console.log("USERDATA---",userData);
                    console.log("COMPLETED---",userData.completedModules);
                    const progress = Math.round((completedModules / totalModules) * 100);
        
                    updatedEnrollments[courseId] = {
                        currentModule: completedModules,
                        progress: progress,
                    };
                }
            });
        
            setUserEnrollments(updatedEnrollments);
        };        

        fetchCourses();
        fetchUserEnrollments();
    }, [user]);

    const handleEnroll = (courseId) => {
        navigate(`/courses/${courseId}`);
        // if (!user) {
        //     alert("Please log in first.");
        //     return;
        // }

        // const userRef = doc(db, "userProgress", user.uid);
        // const userSnap = await getDoc(userRef);
        // let userData = userSnap.exists() ? userSnap.data() : { enrolledCourses: {} };

        // userData.modules[courseId] = {
        //     currentModule: 0,
        //     progress: 0,
        // };

        // await setDoc(userRef, userData);

        // setUserEnrollments((prev) => ({
        //     ...prev,
        //     [courseId]: { currentModule: courseId, progress: 0 },
        // }));
    };

    const colors = ["#a43bcb", "#e293ac", "#FB9060", "#84d4d8", "#fcce90"];

    // Function to determine text color (black or white) based on background color brightness
    const getTextColor = (hexColor) => {
        const r = parseInt(hexColor.substring(1, 3), 16);
        const g = parseInt(hexColor.substring(3, 5), 16);
        const b = parseInt(hexColor.substring(5, 7), 16);
        const brightness = (r * 299 + g * 587 + b * 114) / 1000; // Luminance formula
        return brightness > 128 ? "#000000" : "#FFFFFF"; // Use black text for light colors, white for dark colors
    };

    return (
        <div className="p-8 mt-40 md:mt-20">
            <h1 className="text-3xl font-bold mb-6 text-center text-black border border-black rounded-xl bg-[#fcce90] p-2 comic-sans-medium">Mentorable Courses</h1>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
                {courses.map((course, index) =>
                //         (
                //             <div key={course.id} className="bg-white shadow-lg rounded-lg overflow-hidden hover:shadow-xl transition-shadow">
                //     <img src={course.thumbnail || "https://via.placeholder.com/300"} alt={course.title} className="w-full h-48 object-cover" />
                //     <div className="p-5">
                //       <h2 className="text-xl font-semibold text-gray-800">{course.title}</h2>
                //       <p className="text-gray-600 text-sm mt-1">{course.description}</p>
                //       {userEnrollments[course.id] ? (
                //         <button className="mt-3 w-full py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition" onClick={() => navigate(`/courses/${course.id}`)}>Continue</button>
                //       ) : (
                //         <button className="mt-3 w-full py-2 bg-green-500 text-white rounded-md hover:bg-green-600 transition" onClick={() => handleEnroll(course.id)}>Enroll</button>
                //       )}
                //     </div>
                //   </div>
                //         )
                {
                    const backgroundColor = colors[index % colors.length]; // Cycle through colors
                    const textColor = getTextColor(backgroundColor);
                    const enrollment = userEnrollments[course.id];
                    // const enrollment = false;
                    console.log("Course", course);
                    return (
                        <div
                            key={course.id}
                            className="border border-black flex flex-col space-y-2 bg-[#a43bcb] shadow-4xl rounded-xl overflow-hidden shadow-3xl hover:shadow-4xl transition-shadow duration-300"
                            style={{ backgroundColor }} // Apply dynamic background color
                        >
                            {/* Course Thumbnail */}
                            <img
                                src={course.thumbnail || "https://via.placeholder.com/300"}
                                alt={course.title}
                                className="w-full h-48 object-cover"
                            />

                            {/* Course Content */}
                            {/* <div
    key={course.id}
    className="border border-black flex flex-col shadow-lg rounded-xl overflow-hidden hover:shadow-xl transition-shadow"
    style={{ backgroundColor }}
> */}
                            {/* Course Thumbnail */}
                            {/* <img
        src={course.thumbnail || "https://via.placeholder.com/300"}
        alt={course.title}
        className="w-full h-48 object-cover"
    /> */}

                            {/* Content Wrapper - Makes Button Stay at the Bottom */}
                            <div className="p-5 flex flex-col flex-grow space-y-2">
                                <h2 className="comic-sans-lesson text-center text-xl font-semibold" style={{ color: textColor }}>
                                    {course.title}
                                </h2>
                                <p className="comic-sans-small text-center text-sm mt-1" style={{ color: textColor }}>
                                    {course.age}
                                </p>
                                <p className="comic-sans-tiny text-center text-lg mt-1 flex-grow" style={{ color: textColor }}>
                                    {course.description}
                                </p>

                                {/* Enrollment Info */}
                                {enrollment && (
                                    <div className="relative pt-3">
                                        <div className="w-full bg-gray-200 rounded-full h-2">
                                            <div
                                                className="bg-blue-500 h-2 rounded-full"
                                                style={{ width: `${enrollment.progress}%` }}
                                            ></div>
                                        </div>
                                        <p className="text-xs text-center mt-1" style={{ color: textColor }}>
                                            Progress: {enrollment.progress}%
                                        </p>
                                    </div>
                                )}

                                {/* Button Wrapper */}
                                <div className="mt-auto">
                                    {course.available === false ? (
                                        <button className="mt-3 w-full py-2 bg-gray-400 hover:bg-gray-400 text-white rounded-md transition cursor-not-allowed" disabled>
                                            Will be Available Soon
                                        </button>
                                    ) : enrollment ? (
                                        <button
                                            className="mt-3 w-full py-2 bg-green-200 hover:bg-green-300 text-gray-700 rounded-md transition"
                                            onClick={() => navigate(`/courses/${course.id}`)}
                                        >
                                            Continue Course
                                        </button>
                                    ) : (
                                        <button
                                            className="mt-3 w-full py-2 bg-green-700 hover:bg-green-500 text-white rounded-md transition"
                                            onClick={() => handleEnroll(course.id)}
                                        >
                                            Enroll In Course
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>

                        // </div>
                    );
                }
                )}
            </div>
        </div>
    );
};

export default CoursesListPage;