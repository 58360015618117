import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { auth, db, signInWithGooglePopup } from "../../firebase";
import { createUserWithEmailAndPassword, signInWithEmailAndPassword } from "firebase/auth";
import { doc, setDoc, getDocs, collection, getDoc } from "firebase/firestore";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import { signInWithCredential, GoogleAuthProvider } from "firebase/auth";
import { setUser } from "../../redux/slices/userSlice";

const Register = () => {
    const [formData, setFormData] = useState({
        fullName: "",
        email: "",
        password: "",
        confirmPassword: "",
    });

    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const pathname = useSelector((state) => state.locationslice);
    const navigate = useNavigate();

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const generateCoachId = async () => {
        const coachesCollection = await getDocs(collection(db, "coaches"));
        const nextId = coachesCollection.docs.length + 1;
        return `C00-${nextId}`;
    };

    const getOrCreateAuthUser = async (email, password) => {
        try {
            // Try signing in first (to check if the email exists)
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            return userCredential.user; // Return existing user
        } catch (err) {
            if (err.code === "auth/user-not-found") {
                // If the user does not exist, create a new one
                const newUserCredential = await createUserWithEmailAndPassword(auth, email, password);
                return newUserCredential.user; // Return new user
            } else {
                throw err; // Rethrow any other errors
            }
        }
    };

    const handleRegister = async (e) => {
        e.preventDefault();
        if (formData.password !== formData.confirmPassword) {
            setError("Passwords do not match.");
            return;
        }
        setLoading(true);
        try {
            const user = await getOrCreateAuthUser(formData.email, formData.password);

            // Check if user already exists as a coach in Firestore
            const coachRef = doc(db, "coaches", user.uid);
            const coachSnap = await getDoc(coachRef);
            if (coachSnap.exists()) {
                setError("This email is already registered as a coach.");
                setLoading(false);
                return;
            }

            // Generate unique coach ID
            const coachId = await generateCoachId();

            // Store coach data in Firestore
            await setDoc(coachRef, {
                uid: user.uid,
                coachId: coachId,
                name: formData.fullName,
                email: formData.email,
                role: "coach",
                status: "Pending Approval", // Coach needs approval
                profileCompleted: false,
            });

            alert("Coach Registration Successful! Awaiting approval.");
            navigate("/coach/signin");
        } catch (err) {
            setError(err.message);
        }
        setLoading(false);
    };

    const handleSuccess = async (response) => {
        console.log("Google Sign-In Success:", response);

        try {
            // Send the ID token to the backend for verification
            const res = await fetch("https://mentor-able.com/auth/google-signin", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ token: response.credential }),
            });

            const data = await res.json();
            if (!res.ok) throw new Error("Backend authentication failed");

            const googleIdToken = response.credential;
            const credential = GoogleAuthProvider.credential(googleIdToken);
            const userCredential = await signInWithCredential(auth, credential);
            const user = userCredential.user;

            console.log("Firebase login successful:", user);

            // Reference the coach collection in Firestore
            const coachRef = doc(db, "coaches", user.uid);
            const coachSnap = await getDoc(coachRef);

            if (coachSnap.exists()) {
                // Coach account already exists, navigate based on profile completion
                const coachData = coachSnap.data();
                if (coachData.profileCompleted) {
                    navigate("/coach/");
                } else {
                    navigate("/coach/complete-profile");
                }
                return;
            }

            // If coach does not exist, create a new coach profile
            const coachId = await generateCoachId();

            const coachData = {
                uid: user.uid,
                coachId: coachId,
                name: data.user.name || user.displayName || "",
                email: data.user.email || user.email || "",
                role: "coach",
                status: "Pending Approval", // Coach needs admin approval
                profileCompleted: false,
            };

            await setDoc(coachRef, coachData);

            alert("Coach account created successfully! Awaiting approval.");
            navigate("/coach/complete-profile");

        } catch (err) {
            console.error("Login process failed:", err);
            setError(err.message);
        }
    };

    const handleError = () => {
        console.log("Google Sign-In Failed");
    };

    const loginWithGoogle = async () => {
        try {
            const user = await signInWithGooglePopup();
            console.log("User signed in:", user);

            if (!user.emailVerified) {
                alert("Please verify your email before logging in.");
                return;
            }

            // Check if user exists as a coach
            const coachRef = doc(db, "coaches", user.uid);
            const coachSnap = await getDoc(coachRef);

            if (!coachSnap.exists()) {
                // If coach does not exist, register them
                await setDoc(coachRef, {
                    uid: user.uid,
                    name: user.displayName,
                    email: user.email,
                    role: "coach",
                    status: "Pending Approval",
                    profileCompleted: false,
                });

                alert("Coach account created. Awaiting approval.");
                navigate("/coach/signin"); // Redirect to profile completion
            } else {
                // Coach already exists, check if profile is completed
                const coachData = coachSnap.data();
                if (!coachData.profileCompleted) {
                    navigate("/coach/complete-profile");
                } else {
                    navigate("/coach/dashboard"); // Redirect to the coach dashboard
                }
            }
        } catch (error) {
            console.error("Sign-in error:", error.message);
            alert(`Login failed: ${error.message}`);
        }
    };

    return (
        <div className="min-h-screen flex items-center justify-center md:mt-10">
            <div className="area w-full top-0 left-0 h-full">
                <ul className="circles">
                    <li className="bg-[#84d4d8]"></li>
                    <li className="bg-[#a43bcb]"></li>
                    <li className="bg-[#e293ac]"></li>
                    <li className="bg-[#fb9160]"></li>
                    <li className="bg-[#84d4d8]"></li>
                    <li className="bg-[#fcce90]"></li>
                    <li className="bg-[#a43bcb]"></li>
                    <li className="bg-[#fb9160]"></li>
                    <li className="bg-[#e293ac]"></li>
                    <li className="bg-[#84d4d8]"></li>
                    <li className="bg-[#fcce90]"></li>
                    <li className="bg-[#e293ac]"></li>
                    <li className="bg-[#a43bcb]"></li>
                    <li className="bg-[#fb9160]"></li>
                </ul>
            </div>
            <div className="bg-white/90 shadow-lg rounded-lg p-8 max-w-md w-full">
                <h2 className="comic-sans-medium text-2xl font-bold text-gray-800 mb-6 text-center">Coach Registration</h2>
                <form onSubmit={handleRegister}>
                    <div className="mb-4">
                        <label htmlFor="name" className="comic-sans-small block text-gray-800 font-medium mb-1">
                            Full Name
                        </label>
                        <input
                            type="text"
                            id="fullName"
                            name="fullName"
                            className="w-full bg-gray-100 border border-gray-300 rounded-md p-2 focus:outline-none focus:ring focus:ring-[#F0CF2D]"
                            placeholder="Enter your full name"
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="email" className="comic-sans-small block text-gray-800 font-medium mb-1">
                            Email
                        </label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            className="w-full bg-gray-100 border border-gray-300 rounded-md p-2 focus:outline-none focus:ring focus:ring-[#F0CF2D]"
                            placeholder="Enter your email"
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="password" className="comic-sans-small block text-gray-800 font-medium mb-1">
                            Password
                        </label>
                        <input
                            type="password"
                            id="password"
                            name="password"
                            className="w-full bg-gray-100 border border-gray-300 rounded-md p-2 focus:outline-none focus:ring focus:ring-[#F0CF2D]"
                            placeholder="Create a password"
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="password" className="comic-sans-small block text-gray-800 font-medium mb-1">
                            Confirm Password
                        </label>
                        <input
                            type="password"
                            id="confirmPassword"
                            name="confirmPassword"
                            className="w-full bg-gray-100 border border-gray-300 rounded-md p-2 focus:outline-none focus:ring focus:ring-[#F0CF2D]"
                            placeholder="Confirm Password"
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <button type="submit" className="mb-4 w-full bg-[#F0CF2D] text-gray-800 py-2 rounded-md hover:bg-[#E1BC28] font-semibold flex items-center justify-center" disabled={loading}>
                        {loading ? (
                            <svg className="animate-spin h-5 w-5 mr-2 border-t-2 border-white rounded-full" viewBox="0 0 24 24"></svg>
                        ) : (
                            "Register"
                        )}
                    </button>
                </form>
                {error && <p className="text-lg text-red-500">{error}</p>}
                <GoogleOAuthProvider clientId="762192583663-rf9tkpeu3u88c0slo4vgqglqcfsigq2u.apps.googleusercontent.com">
                    <GoogleLogin

                        onSuccess={handleSuccess}
                        onError={handleError}
                        useOneTap
                    />
                </GoogleOAuthProvider>
                <p className="comic-sans-tiny text-gray-600 text-sm text-center mt-4">
                    Already registered?{" "}
                    <Link to="/coach/signin" className="text-gray-800 hover:text-[#F0CF2D] hover:underline font-medium">
                        Log in here
                    </Link>
                </p>
            </div>
        </div>
    );
};

export default Register;
