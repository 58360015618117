import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Input,
  Checkbox,
} from "@material-tailwind/react";

import { useSelector, useDispatch } from "react-redux";
import { NavLink, useNavigate, useLocation } from "react-router-dom";

import SignInForm from "./SignInForm";
import SignUpForm from "./SignUpForm";

import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendEmailVerification,
  sendPasswordResetEmail
} from "firebase/auth";

import { addDoc, collection, doc, updateDoc, arrayUnion } from "firebase/firestore";

import { auth, signInWithGooglePopup, db } from "../../../firebase";
// import { db } from "../../../firebase";

import {
  setSignInOpen,
  setSignUpOpen,
  setCloseAll,
} from "../../../redux/slices/authDialogSlice";
import { listPathName } from "../../../redux/slices/locationSlice";
import { setUser } from "../../../redux/slices/userSlice";
import Modal from "./Modal";

const SignInDialog = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
    const [message, setMessage] = useState("");
      const [email, setEmail] = useState("");

  const handleOpen = () => dispatch(setSignInOpen());

  const isSignInOpen = useSelector((state) => state.authDialog.isSignInOpen);
  const isSignUpOpen = useSelector((state) => state.authDialog.isSignUpOpen);
  const pathname = useSelector((state) => state.locationslice);

  // console.log("path in dialog", pathname);

  const onSignInSubmit = (data) => { };

  const handleRegister = async (data) => {
    try {
      const response = await fetch("http://localhost:9000/auth/register", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          email: data.email,
          password: data.password,
          firstname: data.firstname,
          lastname: data.lastname,
          phoneNumber: data.phoneNumber,
        }),
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        console.error("Error:", errorData);
        alert(errorData.message || "Something went wrong.");
      } else {
        const result = await response.json();
        alert(result.message);
      }
    } catch (error) {
      console.error("Error during registration:", error);
      alert("Something went wrong. Please try again.");
    }
  };

  // const signupWithUsernameAndPassword = async (e, email, password, data) => {
  //   e.preventDefault();
  //   setIsLoading(true);
  //   // const user = userCredential.user;
  //   // const userArray = [...e.target.elements];
  //   // console.log(userArray);

  //   await createUserWithEmailAndPassword(auth, email, password)
  //     .then((userCredential) => {
  //       setTimeout(() => {
  //         const user = userCredential.user;
  //         dispatch(setUser());
  //         auth.signOut();
  //         sendEmailVerification(user);
  //         // const { firstname, lastname, email, phoneNumber } = data;

  //         const docRef = addDoc(collection(db, "users"), {
  //           ...data,
  //           uid: user?.uid,
  //         });

  //         console.log("docRef", docRef);

  //         // handleClose();

  //         handleLogin();
  //         // navigate(pathname);

  //         // handleClose();

  //         console.log(user);
  //         //   setIsLoading(false);
  //       }, 5000);
  //     })
  //     .catch((error) => {
  //       const errorCode = error.code;
  //       const errorMessage = error.message;
  //       console.log(errorCode, errorMessage);
  //     });
  // };

  const signupWithUsernameAndPassword = async (e, email, password, data) => {
    e.preventDefault();
    setIsLoading(true); // Start loading state
  
    try {
      // Create user with email and password
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
  
      // Dispatch user to the redux store if needed
      dispatch(setUser());
  
      // Send email verification
      await sendEmailVerification(user);
  
      // Add user data to Firestore
      const docRef = await addDoc(collection(db, "users"), {
        ...data,
        uid: user.uid, // Ensure we store the user ID from Firebase auth
      });
  
      console.log("Document successfully written with ID: ", docRef.id);
  
      // After successful signup and Firestore write, handle login and UI state
      handleLogin();

      // Now update the coach's document to include the new student in their assignedStudents array
    const assignedCoachId = data.coachId;  // The coach ID to which the student is assigned

    // Reference to the coach's document
    const coachRef = doc(db, "coaches", assignedCoachId);

    // Update the coach document by adding the new student's UID to the assignedStudents array
    await updateDoc(coachRef, {
      assignedStudents: arrayUnion(user.uid), // arrayUnion ensures the student is added only once
    });

    console.log("Student successfully assigned to the coach");
  
      // Optionally, navigate to another page or close modal
      // navigate(pathname);
  
    } catch (error) {
      console.error("Error during signup:", error.message);
      alert("Error during signup. Please try again later.");
    } finally {
      setIsLoading(false); // Stop loading state
    }
  };

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    setError("");
    setMessage("");
    console.log("CLICKED")

    if (!email) {
      setError("Please enter your email first.");
      return;
    }

    try {
      console.log("IN TRY")
      await sendPasswordResetEmail(auth, email);
      setMessage("Password reset email sent! Check your inbox.");
      setError("");
    } catch (err) {
      console.error("Error sending reset email:", err.message);
      setError("Error sending password reset email. Please try again.");
    }
  };
  

  const handleSubmit = async (e, email, password) => {
    e.preventDefault();
    // setError("");
    try {
      await createUserWithEmailAndPassword(auth, email, password);
      //   setValues({
      //     firstname: "",
      //     lastname: "",
      //     email: "",
      //     phone: "",
      //     password: "",
      //   });
      console.log("Registered!");
      handleClose();
    } catch {
      //   console.log(firstname);
    }
  };

  const loginWithUsernameAndPassword = async (e, data) => {
    if(e) e.preventDefault();
    signInWithEmailAndPassword(auth, data.email, data.password)
      .then((userCredential) => {
        const user = userCredential.user;
        if (!user.emailVerified) {
          alert("Please verify your email before logging in.");
          return;
        }
        if (pathname) {
          console.log("Is email verified?", user.emailVerified);
          dispatch(setUser());
          navigate(pathname.pathname);
          handleClose();
          // alert("Login successful!");
          return;
          // navigate(pathname?.pathname);
          // handleClose();
        } else {
          navigate("/courses");
          handleClose();
        }
        console.log(user);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.error(errorCode, errorMessage);
        alert(`Login failed: ${errorMessage}`);
      });
  };

  const loginWithGoogle = async () => {
    try {
      const user = await signInWithGooglePopup();
      console.log("User signed in:", user);
  
      // Perform any additional actions (e.g., navigate, update UI)
      if (!user.emailVerified) {
        alert("Please verify your email before logging in.");
        return;
      }
      navigate("/courses");
    handleClose();
    } catch (error) {
      console.error("Sign-in error:", error.message);
      alert(`Login failed: ${error.message}`);
    }
  };

  const onSignUpSubmit = (data) => { };

  const handleOpenSignIn = (e) => {
    e.preventDefault();
    // setFirebaseError(null);
    dispatch(setSignInOpen());
  };

  const handleOpenSignUp = (e) => {
    e.preventDefault();
    // setFirebaseError(null);
    dispatch(setSignUpOpen());
  };

  const handleClose = () => {
    console.log(
      "inside handleclose",
      "pathname",
      pathname,
      "locatio",
      location
    );
    if (location?.pathname === "/login") {
      navigate(pathname.pathname);
      dispatch(setCloseAll());
    }
    dispatch(setCloseAll());
    // if (location.pathname === "/login") {
    //   navigate("/");
    // } else {
    //   console.log("else ", pathname.pathname);
    //   navigate(pathname.pathname);
    // }
    // navigate("/");
  };

  const handleLogin = () => {
    dispatch(setSignInOpen());
    // handleshowLoginModal(true);
    // handleshowRegistrationModal(false);
  };

  const isDialogOpen = isSignInOpen || isSignUpOpen;

  //   useEffect(() => {
  //     dispatch(setCloseAll());
  //   }, []);

  return (
    // <Dialog className="w-[550px] " size="xs" open={isDialogOpen}>
    <Modal open={isDialogOpen} onClose={handleClose} className="mt-20">
      <Card className="mx-auto max-w-[30rem] mt-8  ">
        <CardBody className="flex flex-col gap-6">
          {isSignInOpen && (
            <>
              <Typography variant="h4" color="blue-gray" className="comic-sans-medium">
                Sign In
              </Typography>
              <Typography
                className="mb-3 font-normal comic-sans-small"
                variant="paragraph"
                color="gray"
              >
                Enter your email and password to Sign In.
              </Typography>
            </>
          )}

          {isSignUpOpen && (
            <>
              <Typography variant="h4" color="blue-gray" className="comic-sans-medium">
                Sign Up
              </Typography>
              <Typography
                className="mb-3 font-normal comic-sans-small"
                variant="paragraph"
                color="gray"
              >
                Enter your email and password to Sign Up.
              </Typography>
            </>
          )}
          {isSignInOpen && (
            <SignInForm
              handleOpenSignUp={handleOpenSignUp}
              onSubmit={onSignInSubmit}
              openModal={isDialogOpen}
              handleClose={handleClose}
              loginWithUsernameAndPassword={loginWithUsernameAndPassword}
              loginWithGoogle={loginWithGoogle}
              // forgotPassword={handleForgotPassword}
            />
          )}
          {isSignUpOpen && (
            <SignUpForm
              handleSubmit={handleSubmit}
              signupWithUsernameAndPassword={signupWithUsernameAndPassword}
              handleOpenSignIn={handleOpenSignIn}
              openModal={isDialogOpen}
              handleClose={handleClose}
              //   loginWithUsernameAndPassword={loginWithUsernameAndPassword}
              loginWithGoogle={loginWithGoogle}
              onSubmit={onSignUpSubmit}
            />
          )}

          <Typography>
            {isSignInOpen && (
              <Typography variant="small" className="mt-4 flex justify-center comic-sans-tiny">
                Don&apos;t have an account?
                <Typography
                  as="a"
                  href="#signup"
                  variant="small"
                  color="blue-gray"
                  className="ml-1 font-bold comic-sans-tiny"
                  onClick={handleOpenSignUp}
                >
                  Sign Up
                </Typography>
              </Typography>
            )}

            {isSignUpOpen && (
              <Typography variant="small" className=" flex justify-center comic-sans-tiny">
                Already have an account?
                <Typography
                  as="a"
                  href="#signup"
                  variant="small"
                  color="blue-gray"
                  className="ml-1 font-bold comic-sans-tiny"
                  onClick={handleOpenSignIn}
                >
                  Log in
                </Typography>
              </Typography>
            )}
          </Typography>
        </CardBody>
      </Card>
    </Modal>

    // </Dialog>
  );
};

export default SignInDialog;
