import React, { useState } from "react";
import { storage, auth, db } from "../../firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { doc, updateDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { resolve } from "path-browserify";
import { uploadToGoogleDrive } from "../../googleDriveAuth";

const CompleteProfile = () => {
    const [formData, setFormData] = useState({
        phoneNumber: "",
        degree: "",
        university: "",
        subcity: "",
        woreda: ""
    });
    const [photo, setPhoto] = useState(null);
    const [cv, setCv] = useState(null);
    const [uploadProgress, setUploadProgress] = useState({ photo: 0, cv: 0 });
    const [uploading, setUploading] = useState(false);
    const [status, setStatus] = useState("Pending Approval");
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const userId = auth.currentUser?.uid;

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleFileChange = (e, type) => {
        if (type === "photo") setPhoto(e.target.files[0]);
        if (type === "cv") setCv(e.target.files[0]);
    };

    const handleFileUpload = async (file, type) => {
        if (!file) return null;
    
        console.log(`Uploading ${type}:`, file.name, file.type);
        setUploading(true);
    
        // Define allowed file types
        const imageTypes = ["image/jpeg", "image/png", "image/gif"];
        const documentTypes = [
            "application/pdf",
            "application/msword",
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        ];
    
        // Upload to Uploadcare if it's an image
        if (imageTypes.includes(file.type)) {
            const formData = new FormData();
            formData.append("UPLOADCARE_PUB_KEY", "b3f0dbde0df8afb4a197");
            formData.append("file", file);
    
            try {
                const response = await fetch("https://upload.uploadcare.com/base/", {
                    method: "POST",
                    body: formData,
                });
    
                const text = await response.text();
                console.log(`UploadCare response for ${type}:`, text);
    
                if (!text.startsWith("{")) {
                    console.error(`Invalid response for ${type}:`, text);
                    alert(`Upload failed for ${type}. Response: ${text}`);
                    setUploading(false);
                    return null;
                }
    
                const data = JSON.parse(text);
                if (!data.file) {
                    console.error(`${type} upload failed.`, data);
                    alert(`Upload failed for ${type}.`);
                    setUploading(false);
                    return null;
                }
    
                const fileUrl = `https://ucarecdn.com/${data.file}/`;
                console.log(`${type} uploaded successfully:`, fileUrl);
                setUploading(false);
                return fileUrl;
            } catch (error) {
                console.error(`Upload failed for ${type}:`, error);
                alert(`Error uploading ${type}. Check console for details.`);
                setUploading(false);
                return null;
            }
        }
    
        // Upload to GoFile.io for documents (CVs)
        if (documentTypes.includes(file.type)) {
            const formData = new FormData();
            formData.append("file", file);
    
            try {
                const response = await fetch("https://store1.gofile.io/uploadFile", {
                    method: "POST",
                    body: formData,
                });
    
                const data = await response.json();
                if (!data.data || !data.data.downloadPage) {
                    console.error(`GoFile upload failed for ${type}:`, data);
                    alert(`Failed to upload ${type} to GoFile.`);
                    setUploading(false);
                    return null;
                }
    
                const fileUrl = data.data.downloadPage;
                console.log(`${type} uploaded successfully to GoFile:`, fileUrl);
                setUploading(false);
                return fileUrl;
            } catch (error) {
                console.error(`Error uploading ${type} to GoFile:`, error);
                alert(`Error uploading ${type}. Check console for details.`);
                setUploading(false);
                return null;
            }
        }
    
        // If file type is not supported
        console.error(`${type} upload failed: Unsupported file type.`);
        alert("Unsupported file type. Please upload an image or a document.");
        setUploading(false);
        return null;
    };
    
    
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!userId) {
            alert("You must be logged in.");
            return;
        }

        setUploading(true);
        setLoading(true);
        try {
            const photoUrl = photo ? await handleFileUpload(photo, "photo") : null;
            const cvUrl = cv ? await handleFileUpload(cv, "cv") : null;

            console.log("Photo URL:", photoUrl);
            console.log("CV URL:", cvUrl);

            const coachRef = doc(db, "coaches", userId);
            await updateDoc(coachRef, {
                ...formData,
                photo: photoUrl,
                cv: cvUrl,
                status: "Pending Approval",
                profileCompleted: "true"
            });

            setStatus("Pending Approval");
            navigate("/coach/");
            alert("Profile updated successfully!");
        } catch (error) {
            console.error("Error updating profile:", error);
            alert("Failed to update profile.");
        }

        setUploading(false);
        setLoading(false);
    };

    return (
        <div className="min-h-screen flex items-center justify-center p-6 pt-[110px]">
            <div className="bg-white/90 shadow-lg rounded-lg p-6 max-w-2xl w-full">
                <h2 className="comic-sans-medium text-2xl font-bold text-gray-800 mb-4 text-center">Complete Your Profile</h2>
                <p className="comic-sans-tiny text-center text-gray-600 text-sm mb-6">
                    Your profile is <span className="font-semibold text-yellow-600">{status}</span>. In the meantime, complete your profile.
                </p>

                <form onSubmit={handleSubmit} className="space-y-4">
                    <div>
                        <label className="text-gray-700 font-medium mb-1">Profile Picture</label>
                        <input type="file" accept="image/*" onChange={(e) => handleFileChange(e, "photo")} className="border border-gray-300 p-2 rounded w-full" required />
                        {uploadProgress.photo > 0 && <progress value={uploadProgress.photo} max="100" className="w-full mt-2" />}
                    </div>
                    <div>
                        <label className="text-gray-700 font-medium mb-1">Upload CV</label>
                        <input type="file" accept=".pdf,.doc,.docx" onChange={(e) => handleFileChange(e, "cv")} className="border border-gray-300 p-2 rounded w-full" required />
                        {uploadProgress.cv > 0 && <progress value={uploadProgress.cv} max="100" className="w-full mt-2" />}
                    </div>
                    <div>
                        <label className="text-gray-700 font-medium mb-1">Phone Number</label>
                        <input type="text" name="phoneNumber" value={formData.phoneNumber} onChange={handleChange} className="border border-gray-300 p-2 rounded w-full" placeholder="Enter your phone number" required />
                    </div>
                    <div>
                        <label className="text-gray-700 font-medium mb-1">University Degree</label>
                        <input type="text" name="degree" value={formData.degree} onChange={handleChange} className="border border-gray-300 p-2 rounded w-full" placeholder="Enter your major" required />
                    </div>
                    <div>
                        <label className="text-gray-700 font-medium mb-1">Name of University You Graduated From</label>
                        <input type="text" name="university" value={formData.university} onChange={handleChange} className="border border-gray-300 p-2 rounded w-full" placeholder="Enter your university name" required />
                    </div>
                    <div>
                        <label className="text-gray-700 font-medium mb-1">Kifle Ketema</label>
                        <input type="text" name="subcity" value={formData.subcity} onChange={handleChange} className="border border-gray-300 p-2 rounded w-full" placeholder="Enter your specific address" required />
                    </div>
                    <div>
                        <label className="text-gray-700 font-medium mb-1">Woreda</label>
                        <input type="text" name="woreda" value={formData.woreda} onChange={handleChange} className="border border-gray-300 p-2 rounded w-full" placeholder="Enter your specific address" required />
                    </div>
                    <button type="submit" className="w-full bg-[#F0CF2D] text-gray-800 py-2 rounded-md hover:bg-blue-700 font-semibold">
                        {uploading ? "Uploading..." : "Update Profile"}
                    </button>
                </form>
            </div>
        </div>
    );
};

export default CompleteProfile;