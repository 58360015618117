import { useState, useEffect, useRef } from "react";
import { db } from "../firebase";
import { collection, getDocs } from "firebase/firestore";
import { useParams } from "react-router-dom";
import { Menu, X } from "lucide-react";

const CoursePage = () => {
  const { courseId } = useParams();
  const [modules, setModules] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedLessons, setSelectedLessons] = useState({});
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [currentModuleIndex, setCurrentModuleIndex] = useState(0);
  const [currentLessonIndex, setCurrentLessonIndex] = useState(0);
  const [isNextEnabled, setIsNextEnabled] = useState(false);
  const [showQuiz, setShowQuiz] = useState(false);
  const lessonRef = useRef(null);

  useEffect(() => {
    const fetchModules = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "modules"));
        const moduleList = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setModules(moduleList);
      } catch (error) {
        console.error("Error fetching modules:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchModules();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (lessonRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = lessonRef.current;
        setIsNextEnabled(scrollTop + clientHeight >= scrollHeight - 10);
      }
    };

    if (currentLessonIndex === 0) {
      setIsNextEnabled(true);
    } else {
      if (lessonRef.current) {
        lessonRef.current.addEventListener("scroll", handleScroll);
        handleScroll();
      }
    }

    return () => {
      if (lessonRef.current) {
        lessonRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, [currentLessonIndex, currentModuleIndex]);

  const handleLessonSelect = (moduleId, lessonId) => {
    setShowQuiz(false);
    setSelectedLessons((prev) => ({
      ...prev,
      [moduleId]: lessonId,
    }));
  };

  const handleNextLesson = () => {
    const currentModule = modules[currentModuleIndex];
    if (!currentModule) return;
    const moduleLessons = currentModule.lessons || [];

    if (currentLessonIndex < moduleLessons.length - 1) {
      setCurrentLessonIndex((prevIndex) => prevIndex + 1);
    } else {
      setShowQuiz(true);
    }
  };

  if (loading) return <div>Loading...</div>;

  return (
    <div className="flex h-screen mt-40">
      <button
        className="fixed top-4 left-4 z-50 mt-40 md:mt-0 p-2 bg-[#84d4d8] rounded-md md:hidden"
        onClick={() => setIsSidebarOpen(!isSidebarOpen)}
      >
        {isSidebarOpen ? <X size={24} /> : <Menu size={24} />}
      </button>

      <div
        className={`fixed inset-y-0 left-0 mt-40 md:mt-0 h-4/5 bg-[#84d4d8] rounded-2xl shadow-lg p-4 overflow-y-auto transition-transform duration-300 ${isSidebarOpen ? "translate-x-0" : "-translate-x-full"} md:relative md:w-1/4 md:translate-x-0`}
      >
        <h2 className="font-bold mb-4 mt-10 comic-sans-lesson">Course Modules</h2>
        {modules.map((module) => (
          <div key={module.id} className="mb-4">
            <h3 className="comic-sans-small font-semibold">{module.title}</h3>
            <ul>
              {module.lessons.map((lesson) => (
                <li
                  key={lesson.lessonId}
                  onClick={() => handleLessonSelect(module.id, lesson.lessonId)}
                  className={`comic-sans-tiny ${selectedLessons[module.id] === lesson.lessonId ? "font-bold comic-sans-tiny" : ""}`}
                  
                >
                  {lesson.title}
                </li>
              ))}
            </ul>
          </div>
        ))}
        <button
          className="mt-4 px-4 py-2 bg-green-500 text-white rounded w-full"
          onClick={() => setShowQuiz(true)}
        >
          Quiz
        </button>
      </div>

      <div
        className={`w-full h-[calc(100vh-10rem)] md:h-4/5 p-6 mt-10 md:mt-0 rounded-xl transition-all duration-300 ${isSidebarOpen ? "opacity-0" : "opacity-100"} md:w-3/4 md:bg-[#a43bcb]`}
      >
        {showQuiz ? (
          <div className="flex justify-center items-center h-full">
            <iframe width="100%" height="100%" src="https://www.youtube.com/embed/2OEwV2A-e8E?si=Rt1IQkWZ7bS2qD6M" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
          </div>
        ) : (
          modules.length > 0 && (
            <>
              <h1 className="text-2xl text-center text-white comic-sans-medium font-bold">
                {modules[currentModuleIndex].title}
              </h1>
              <div
                ref={lessonRef}
                className="mt-4 p-4 bg-white opacity-80 shadow rounded-lg overflow-y-auto h-[calc(100vh-10rem)] md:h-[calc(80vh-10rem)]"
              >
                <div className="text-black comic-sans-lesson mt-2">
                  {modules[currentModuleIndex].lessons[currentLessonIndex].content.map((item, index) => (
                    <p className="text-center comic-sans-lesson my-5" key={index}>
                      {item.value}
                    </p>
                  ))}
                </div>
              </div>
              <div className="flex flex-col md:flex-row justify-between my-4 gap-4">
                <button
                  className="px-4 py-2 bg-gray-400 text-white rounded w-full md:w-auto"
                  disabled={currentLessonIndex === 0}
                  onClick={() => setCurrentLessonIndex(currentLessonIndex - 1)}
                >
                  Previous
                </button>
                <button
                  className={`px-4 py-2 rounded w-full md:w-auto ${isNextEnabled ? "bg-blue-500 hover:bg-blue-600 text-white" : "bg-gray-300 cursor-not-allowed"}`}
                  disabled={!isNextEnabled}
                  onClick={handleNextLesson}
                >
                  {currentLessonIndex === modules[currentModuleIndex].lessons.length - 1 ? "Quiz" : "Next"}
                </button>
              </div>
            </>
          )
        )}
      </div>
    </div>
  );
};

export default CoursePage;

/// -------------------------------------
// import { useState, useEffect, useRef } from "react";
// import { db, auth } from "../firebase";
// import { collection, doc, getDocs, getDoc, updateDoc, setDoc, where, query } from "firebase/firestore";
// import { useParams } from "react-router-dom";
// import { Modal, Button } from "@mui/material";
// import { getAuth } from "firebase/auth";
// import QuizComponent from "./QuizComponent";
// import { Menu, X } from "lucide-react";
// import { all } from "axios";
// // import { query } from "express";

// const CoursePage = () => {
//   const { courseId } = useParams();
//   const [modules, setModules] = useState([]);
//   const [currentModuleIndex, setCurrentModuleIndex] = useState(0);
//   const [currentLessonIndex, setCurrentLessonIndex] = useState(0);
//   const [completedLessons, setCompletedLessons] = useState({});
//   const [isNextEnabled, setIsNextEnabled] = useState(false);
//   const [userProgress, setUserProgress] = useState({});
//   const [coachingStatus, setCoachingStatus] = useState("Not started yet");
//   const [quizScores, setQuizScores] = useState({});
//   const [quizData, setQuizData] = useState(null);
//   const [currentModule, setCurrentModule] = useState(null);
//   const [showQuiz, setShowQuiz] = useState(false);
//   const [loading, setLoading] = useState(true);
//   const [selectedLessons, setSelectedLessons] = useState({});
//   const [isSidebarOpen, setIsSidebarOpen] = useState(false);
//   const lessonRef = useRef(null);
//   const user = auth.currentUser;

//   useEffect(() => {
//     const fetchCurrentModule = async () => {
//       try {
//         // Fetch the last visited module ID from local storage
//         // localStorage.setItem("currentModuleId", "module1");
//         const savedModuleId = localStorage.getItem("currentModuleId");
//         console.log("savedModuleId",savedModuleId)
//         if (savedModuleId) {
//           const moduleRef = doc(db, "modules", savedModuleId);
//           const moduleSnap = await getDoc(moduleRef);

//           if (moduleSnap.exists()) {
//             const moduleData = { id: moduleSnap.id, ...moduleSnap.data() }
//             setModules([moduleData]); // Load only the current module
//             setCurrentModule(moduleData);
//           }
//         } else {
//           // If no saved module, load the first one
//           const querySnapshot = await getDocs(collection(db, "modules"));
//           const moduleList = querySnapshot.docs.map((doc) => ({
//             id: doc.id,
//             ...doc.data(),
//           }));

//           if (moduleList.length > 0) {
//             setModules([moduleList[0]]); // Load only the first module
//             setCurrentModule(moduleList[0])
//             localStorage.setItem("currentModuleId", moduleList[0].id); // Save in localStorage
//             // console.log("CURRENT MODULE-----",moduleList[0].id)
//             // localStorage.setItem("currentModuleId", moduleList[moduleIndex].id);
//           }
//         }
//       } catch (error) {
//         console.error("Error fetching module:", error);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchCurrentModule();
//   }, []);

//   useEffect(() => {
//     const fetchProgress = async () => {
//       if (user) {
//         const progressRef = doc(db, "userProgress", user.uid);
//         const progressSnap = await getDoc(progressRef);
//         if (progressSnap.exists()) {
//           setUserProgress(progressSnap.data());
//         }
//       }
//     };
//     fetchProgress();
//   }, [user]);

//   useEffect(() => {
//     if (!modules.length || !userProgress) return;

//     const storedModuleId = localStorage.getItem("currentModuleId");
//     const storedModuleIndex = modules.findIndex((m) => m.id === storedModuleId);
//     console.log("storedModuleIndex", storedModuleIndex)
//     const moduleIndex = storedModuleIndex !== -1 ? storedModuleIndex : 0;

//     const moduleLessons = modules[moduleIndex]?.lessons || [];
//     let firstUncompletedLesson = null;

//     for (const lesson of moduleLessons) {
//       if (!userProgress.completedLessons?.[lesson.lessonId]) {
//         firstUncompletedLesson = lesson.lessonId;
//         break;
//       }
//     }

//     setCurrentModuleIndex(moduleIndex);
//     setCurrentLessonId(firstUncompletedLesson || moduleLessons[0]?.lessonId || null);
//     const firstUncompletedLessonIndex = moduleLessons.findIndex(l => l.lessonId === firstUncompletedLesson);
//     setCurrentLessonIndex(firstUncompletedLessonIndex !== -1 ? firstUncompletedLessonIndex : 0);
//   }, [modules, userProgress]);


//   useEffect(() => {
//     if (currentModuleIndex < modules.length) {
//       localStorage.setItem("currentModuleId", modules[currentModuleIndex].moduleId);
//     }
//   }, [currentModuleIndex, modules]);

//   // useEffect(() => {
//   //   if (modules.length === 0) return;

//   //   const currentModule = modules[currentModuleIndex];

//   //   if (currentModule && currentModule.lessons.length > 0) {
//   //     // Retrieve the last visited lesson for this module
//   //     const lastVisitedLessonId = localStorage.getItem(`lastVisitedLesson_${currentModule.moduleId}`);

//   //     let lastVisitedIndex = 0;

//   //     if (lastVisitedLessonId) {
//   //       const foundIndex = currentModule.lessons.findIndex(lesson => lesson.lessonId === lastVisitedLessonId);
//   //       if (foundIndex !== -1) {
//   //         lastVisitedIndex = foundIndex;
//   //       }
//   //     } else {
//   //       // If no last visited lesson, find the first uncompleted one
//   //       for (let i = 0; i < currentModule.lessons.length; i++) {
//   //         if (!completedLessons[currentModule.lessons[i].lessonId]) {
//   //           lastVisitedIndex = i;
//   //           break;
//   //         }
//   //       }
//   //     }

//   //     setCurrentLessonIndex(lastVisitedIndex);
//   //     setSelectedLessons((prev) => ({
//   //       ...prev,
//   //     }));
//   //   }
//   // }, [modules, completedLessons, currentModuleIndex]);


//   useEffect(() => {
//     if (modules.length > 0) {
//       setSelectedLessons((prev) => ({
//         ...prev,
//         [modules[0].moduleId]: modules[0].lessons[0].lessonId,
//       }));
//     }
//   }, [modules]);

//   const [currentLessonId, setCurrentLessonId] = useState(null);

//   // useEffect(() => {
//   //   if (selectedLessons[module.moduleId]) {
//   //     setCurrentLessonId(selectedLessons[module.moduleId]);
//   //   }
//   // }, [selectedLessons]);



//   // // Fetch course modules from Firestore
//   // useEffect(() => {
//   //   const fetchModules = async () => {
//   //     try {
//   //       const courseRef = doc(db, "courses", courseId);
//   //       const courseSnap = await getDoc(courseRef);
//   //       if (courseSnap.exists()) {
//   //         const courseData = courseSnap.data();
//   //         const modulePromises = courseData.modules.map(async (moduleId) => {
//   //           const moduleRef = doc(db, "modules", moduleId);
//   //           const moduleSnap = await getDoc(moduleRef);
//   //           return moduleSnap.exists() ? moduleSnap.data() : null;
//   //         });
//   //         const modulesData = await Promise.all(modulePromises);
//   //         setModules(modulesData.filter(Boolean));
//   //       } else {
//   //         setModules([]);
//   //       }
//   //     } catch (error) {
//   //       console.error("Error fetching modules:", error);
//   //       setModules([]);
//   //     }
//   //   };
//   //   fetchModules();
//   // }, [courseId]);

//   const fetchModules = async () => {
//     try {
//       const querySnapshot = await getDocs(collection(db, "modules"));
//       const moduleList = querySnapshot.docs.map((doc) => ({
//         id: doc.id,
//         ...doc.data(),
//       }));
  
//       setModules(moduleList); // Load all modules at once
  
//       if (moduleList.length > 0) {
//         const savedModuleId = localStorage.getItem("currentModuleId");
//         const foundModule = moduleList.find(m => m.id === savedModuleId) || moduleList[0];
//         setCurrentModule(foundModule);
//       }
//     } catch (error) {
//       console.error("Error fetching modules:", error);
//     } finally {
//       setLoading(false);
//     }
//   };
  
//   useEffect(() => {
//     fetchModules();
//   }, []);

  
//   const handleModuleChange = async (moduleIndex) => {
//     setLoading(true);
//     try {
//       const selectedModule = modules[moduleIndex];

//       if (!selectedModule) {
//         const querySnapshot = await getDocs(collection(db, "modules"));
//         const moduleList = querySnapshot.docs.map((doc) => ({
//           id: doc.id,
//           ...doc.data(),
//         }));

//         setModules([moduleList[moduleIndex]]);
//         setCurrentModule(moduleList[moduleIndex]);
//         localStorage.setItem("currentModuleId", moduleList[moduleIndex].id);
//       } else {
//         setModules([selectedModule]);
//         setCurrentModule(selectedModule);
//         localStorage.setItem("currentModuleId", selectedModule.id);
//       }

//       // setCurrentModuleIndex(moduleIndex);
//       // localStorage.setItem("currentModuleId", modules[moduleIndex].id);
//     } catch (error) {
//       console.error("Error fetching module:", error);
//     } finally {
//       setLoading(false);
//     }
//   };


//   // useEffect(() => {
//   //   const fetchModules = async () => {
//   //     const modulesRef = collection(db, "modules");
//   //     const moduleSnapshot = await getDocs(modulesRef);
//   //     const modulesList = moduleSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
//   //     setModules(modulesList);
//   //   };

//   //   fetchModules();
//   // }, []);

//   const fetchQuiz = async (moduleId) => {
//     console.log("Fetching quiz for module:", moduleId);
//     const quizzesRef = collection(db, "quizzes");
//     console.log("QUIZREF", quizzesRef);
//     const q = query(quizzesRef, where("moduleId", "==", moduleId))
//     const querySnap = await getDocs(q);
//     console.log("QUIZ SNAP", querySnap);
//     if (!querySnap.empty) {
//       const quizDoc = querySnap.docs[0];
//       console.log("Quiz Found:", quizDoc.data());
//       setQuizData(quizDoc.data());
//       setTimeout(() => setShowQuiz(true), 100);
//       // console.log("Quiz data", quizData.questions);
//       // console.log("Before setting modal:", showQuiz); // Debugging line
//       // setCurrentModule(moduleId);
//       // setShowQuiz(true);
//       // console.log("After setting modal:", showQuiz); // Debugging line

//     } else {
//       console.log("No quiz found for moduleId:", moduleId);
//     }
//   };

//   useEffect(() => {
//     console.log("Modal visibility changed:", showQuiz);
//   }, [showQuiz]);

//   const goToNextModule = () => {
//     if (currentModuleIndex < modules.length - 1) {
//       const nextModule = modules[currentModuleIndex + 1];
//       setCurrentModuleIndex((prev) => prev + 1);
//       setCurrentModule(nextModule);
//       localStorage.setItem("currentModuleId", nextModule.moduleId); // Update localStorage
//     } else {
//       console.log("All modules completed!");
//     }
//   };


//   const handleQuizCompletion = async (score) => {
//     if (!user || !currentModule) return;

//     const moduleId = currentModule.moduleId;
//     if (score >= 90) {
//       const progressRef = doc(db, "userProgress", user.uid);
//       await setDoc(progressRef, {
//         quizScores: { ...quizScores, [moduleId]: score }
//       }, { merge: true });

//       setQuizScores(prev => ({ ...prev, [moduleId]: score }));

//       console.log("Quiz completed for module:", moduleId, "Score:", score);

//       setTimeout(goToNextModule, 200); // 🎯 Move to the next module automatically!
//     }

//     setShowQuiz(false);
//   };


//   // Load user progress
//   useEffect(() => {
//     const loadUserProgress = async () => {
//       if (!user) return;

//       try {
//         const progressRef = doc(db, "userProgress", user.uid);
//         const progressSnap = await getDoc(progressRef);

//         if (progressSnap.exists()) {
//           console.log("Fetched user progress:", progressSnap.data());

//           setUserProgress(progressSnap.data());
//           setCompletedLessons(progressSnap.data().completedLessons || {});
//           setQuizScores(progressSnap.data().quizScores || {});
//           setCoachingStatus(progressSnap.data().coachingStatus || "Not started yet"); // ✅ Use defined setCoachingStatus
//           const updatedSelectedLessons = {};
//           modules.forEach((module) => {
//             const { lessons, moduleId } = module;
//             let lastVisitedLesson = lessons[0].lessonId; // Default to first lesson

//             for (let lesson of lessons) {
//               if (!progressSnap.data().completedLessons?.[lesson.lessonId]) {
//                 lastVisitedLesson = lesson.lessonId; // Select the first incomplete lesson
//                 break;
//               }
//             }

//             updatedSelectedLessons[moduleId] = lastVisitedLesson;
//           });

//           setSelectedLessons(updatedSelectedLessons);
//           console.log("SELECTED", selectedLessons);

//         } else {
//           console.log("No user progress found.");
//           setUserProgress(null);
//         }
//       } catch (error) {
//         console.error("Error fetching user progress:", error);
//       }

//     };
//     if (user.uid) {
//       loadUserProgress();
//       console.log("SCORE----", quizScores[currentModule]);
//     }
//     // loadUserProgress();
//   }, [user]);

//   useEffect(() => {
//     const handleScroll = () => {
//       if (lessonRef.current) {
//         const { scrollTop, scrollHeight, clientHeight } = lessonRef.current;
//         setIsNextEnabled(scrollTop + clientHeight >= scrollHeight - 10);
//       }
//     };

//     const checkScroll = () => {
//       if (lessonRef.current) {
//         const { scrollHeight, clientHeight } = lessonRef.current;

//         // If scrolling is possible, attach the scroll event listener
//         if (scrollHeight <= clientHeight) {
//           setIsNextEnabled(true);
//         } else {
//           // If no scrolling is needed, enable the button immediately
//           lessonRef.current.addEventListener("scroll", handleScroll);
//           handleScroll(); // Run once to check if already at bottom
//         }
//       }
//     };

//     if (currentLessonIndex === 0) {
//       // Automatically enable the Next button on the first lesson of the module
//       setIsNextEnabled(true);
//     } else {
//       checkScroll(); // Run the check for other lessons
//     }

//     return () => {
//       if (lessonRef.current) {
//         lessonRef.current.removeEventListener("scroll", handleScroll);
//       }
//     };
//   }, [currentLessonIndex, currentModuleIndex]);


//   // Enable Next button when scrolled to bottom
//   // useEffect(() => {
//   //   const handleScroll = () => {
//   //     if (lessonRef.current) {
//   //       const { scrollTop, scrollHeight, clientHeight } = lessonRef.current;
//   //       setIsNextEnabled(scrollTop + clientHeight >= scrollHeight - 10);
//   //     }
//   //   };

//   //   if (lessonRef.current) {
//   //     lessonRef.current.addEventListener("scroll", handleScroll);
//   //   }

//   //   return () => {
//   //     if (lessonRef.current) {
//   //       lessonRef.current.removeEventListener("scroll", handleScroll);
//   //     }
//   //   };
//   // }, [currentLessonIndex, currentModuleIndex]);

//   // Mark lesson as completed

//   const handleLessonSelect = (moduleId, lessonId) => {
//     setSelectedLessons((prev) => ({
//       ...prev,
//       [moduleId]: lessonId, // Track lesson for the specific module
//     }));

//     localStorage.setItem(`lastVisitedLesson_${moduleId}`, lessonId); // Store last visited lesson
//     const lessonIndex = modules[currentModuleIndex].lessons.findIndex((lesson) => lesson.lessonId === lessonId);
//     setCurrentLessonIndex(lessonIndex);
//   };




//   const updateQuizScore = async (moduleId, score) => {
//     if (!user) return;

//     const updatedScores = { ...quizScores, [moduleId]: score };
//     setQuizScores(updatedScores);

//     const progressRef = doc(db, "userProgress", user.uid);
//     await setDoc(progressRef, { quizScores: updatedScores }, { merge: true });
//   };

//   const markLessonComplete = async () => {
//     if (!user) return;

//     // Get current lesson ID
//     const currentLesson = modules[currentModuleIndex].lessons[currentLessonIndex];
//     if (!currentLesson) return;

//     const lessonId = currentLesson.lessonId;

//     // Mark only this lesson as completed
//     const updatedLessons = { ...completedLessons, [lessonId]: true };

//     // Update Firestore
//     const progressRef = doc(db, "userProgress", user.uid);
//     await setDoc(progressRef, { completedLessons: updatedLessons }, { merge: true });

//     // Update local state
//     setCompletedLessons(updatedLessons);
//   };

//   const handleNextLesson = async () => {
//     const currentModule = modules[currentModuleIndex];
  
//     if (!currentModule) return;
  
//     const moduleLessons = currentModule.lessons || [];
  
//     // Mark the current lesson as completed
//     await markLessonComplete();
  
//     if (currentLessonIndex < moduleLessons.length - 1) {
//       // Move to the next lesson within the same module
//       setCurrentLessonIndex((prevIndex) => prevIndex + 1);
//     } else {
//       // If this is the last lesson of the module, move to the next module without quiz checks
//       if (currentModuleIndex < modules.length - 1) {
//         setCurrentModuleIndex((prevIndex) => prevIndex + 1);
//         setCurrentLessonIndex(0);
  
//         // Ensure lesson starts at the first lesson of the next module
//         setTimeout(() => {
//           const nextModuleLessons = modules[currentModuleIndex + 1]?.lessons || [];
//           if (nextModuleLessons.length > 0) {
//             setCurrentLessonIndex(0);
//             setSelectedLessons((prev) => ({
//               ...prev,
//               [modules[currentModuleIndex + 1].moduleId]: nextModuleLessons[0].lessonId,
//             }));
//           }
//         }, 100);
//       } else {
//         console.log("All modules completed!");
//       }
//     }
//   };
  

//   // Handle Next button click FOR NOWWWW
//   // const handleNextLesson = async () => {
//   //   const currentModule = modules[currentModuleIndex];

//   //   // Ensure a valid lesson exists
//   //   if (!currentModule) return;

//   //   const moduleId = currentModule.moduleId;
//   //   const moduleLessons = currentModule.lessons || [];

    

//     // Mark the current lesson as completed FOR NOWWW
//   //   await markLessonComplete();

//   //   if (currentLessonIndex < moduleLessons.length - 1) {
//   //     // Move to the next lesson
//   //     console.log("Move to the next lesson")
//   //     setCurrentLessonIndex((prevIndex) => prevIndex + 1);
//   //   } else if (quizScores[moduleId] >= 90) {
//   //     if (currentModuleIndex < modules.length - 1) {
//   //       setCurrentModuleIndex((prev) => prev + 1);
//   //       setCurrentLessonIndex(0);

//   //       // Wait for state to update, then ensure lesson starts at the first lesson of the next module
//   //       setTimeout(() => {
//   //         const nextModuleLessons = modules[currentModuleIndex + 1]?.lessons || [];
//   //         if (nextModuleLessons.length > 0) {
//   //           setCurrentLessonIndex(0);
//   //           setSelectedLessons((prev) => ({
//   //             ...prev,
//   //             [modules[currentModuleIndex + 1].moduleId]: nextModuleLessons[0].lessonId,
//   //           }));
//   //         }
//   //       }, 100);
//   //     } else {
//   //       console.log("All modules completed!");
//   //     }
//   //   } else {
//   //     alert("Complete the quiz before proceeding.");
//   //   }
//   // };

//   const isLastLessonCompleted = () => {
//     const currentModule = modules[currentModuleIndex];
//     if (!currentModule) return false;

//     const lastLesson = currentModule.lessons[currentModule.lessons.length - 1];
//     return userProgress?.completedLessons?.[lastLesson.lessonId] || false;
//   };

//   const resetAllLessons = async () => {
//     if (!user) return;

//     // Fetch the current progress
//     const progressRef = doc(db, "userProgress", user.uid);
//     const progressSnap = await getDoc(progressRef);

//     if (progressSnap.exists()) {
//       const progressData = progressSnap.data();

//       // Set all completedLessons values to false
//       const updatedLessons = Object.keys(progressData.completedLessons || {}).reduce(
//         (acc, lessonId) => {
//           acc[lessonId] = false;
//           return acc;
//         },
//         {}
//       );

//       // Update Firestore
//       await setDoc(progressRef, { completedLessons: updatedLessons }, { merge: true });

//       // Update state
//       setCompletedLessons(updatedLessons);
//     }
//   };

//   if (loading) return <div>Loading...</div>;

//   return (
//     <div className="flex h-screen mt-40">
      
//       {/* Hamburger Menu Button (visible only on small screens) */}
//       <button
//       className="fixed top-4 left-4 z-50 mt-40 p-2 bg-[#84d4d8] rounded-md md:hidden"
//       onClick={() => setIsSidebarOpen(!isSidebarOpen)}
//     >
//       {isSidebarOpen ? <X size={24} /> : <Menu size={24} />}
//     </button>

//     {/* Sidebar */}
//     <div
//       className={`fixed inset-y-0 left-0 bg-[#84d4d8] rounded-2xl shadow-lg mt-40 md:mt-0 p-4 overflow-y-auto transition-transform duration-300 ${
//         isSidebarOpen ? "translate-x-0" : "-translate-x-full"
//       } md:relative md:w-1/4 md:translate-x-0`}
//     >
//         <h2 className="font-bold mb-4 mt-10 comic-sans-lesson">Course Modules</h2>
//         {modules.map((module, moduleIndex) => {
//           const isActiveModule = moduleIndex === currentModuleIndex; // Check if this is the active module
//           const allLessonsCompleted = module.lessons.every((lesson) => completedLessons[lesson.lessonId]);
//           console.log("CURRENT MODULE INDEX", currentModuleIndex);
//           console.log("CURRENT MODULE", module);
//           return (
//             <div key={module.id} className={`mb-4 md:mb-4 ${isActiveModule ? "border-l-4 border-blue-600 pl-2" : ""}`}>
//               <h3
//                 // className={`font-semibold comic-sans-small ${isActiveModule ? "text-blue-600" : "text-gray-500"}`}
//                 className="font-semibold comic-sans-small text-blue-600"
//                 onClick={() => handleModuleChange(moduleIndex)} // Allow switching between modules
//               >
//                 {module.title}
//               </h3>
//               <ul className="ml-4 mt-2 text-sm comic-sans-tiny text-gray-700">
//                 {module.lessons.map((lesson, lessonIndex) => {
//                   const lessonId = lesson.lessonId;
//                   const isCompleted = completedLessons[lessonId] || false;
//                   const isCurrent = selectedLessons[module.moduleId] === lessonId;
//                   const isClickable = lessonIndex === 0 || completedLessons[module.lessons[lessonIndex - 1]?.lessonId];
//                   console.log("IS CURRENT", isClickable);
//                   return (
//                     <li
//                       key={lessonId}
//                       className="flex items-center p-1 rounded text-bold cursor-pointer"

//           //             className={`flex items-center p-1 rounded
//           // ${isCurrent ? " text-bold" : ""}
//           //   // {/* bg-blue-200 */} 
//           // ${isClickable ? "cursor-pointer" : "cursor-not-allowed text-gray-400"}`}

//                       onClick={(e) => {
//                         // e.preventDefault();
//                         if (isClickable) {
//                           handleLessonSelect(module.moduleId, lesson.lessonId);
//                           // setCurrentLessonIndex(lessonIndex);
//                         }
//                       }}
//                     >
//                       {isCompleted ? <span className="mr-2 text-green-500">✔</span> : "🔹"} {lesson.title}
//                     </li>
//                   );
//                 })}
//               </ul>
//               {/* <div className="text-center mt-4"> */}
//                 {/* {quizScores[currentModule?.moduleId] >= 90 ? (
//                   <p>✅ Quiz Passed: {quizScores[currentModule?.moduleId]}%</p>
//                 ) : (
//                   <button
//                     onClick={() => fetchQuiz(currentModule?.moduleId)}
//                     disabled={!isLastLessonCompleted()}
//                   >
//                     Take Quiz
//                   </button>
//                 )} */}

//                 {/* {!userProgress.passedModules?.[module.moduleId] && (
//                   <button 
//                   onClick={() => fetchQuiz(modules[currentModuleIndex].moduleId)}
//                   // className="quiz-button"
//                   disabled={quizScores[modules[currentModuleIndex].moduleId] >= 90}
//                 >
//                   {quizScores[modules[currentModuleIndex].moduleId] >= 90
//                     ? `Quiz Passed: ${quizScores[modules[currentModuleIndex].moduleId]}%`
//                     : "Take Quiz"}
//                 </button>
                
//                 )} */}
//                 {/* 
//                 {quizScores[modules[currentModuleIndex].moduleId].score >= 90 ? (
//                   <p variant="contained" className="comic-sans-tiny p-2 rounded-lg bg-green-100 hover:bg-green-100" disabled>
//                     Quiz Passed - Score: {quizScores[modules[currentModuleIndex].moduleId].score}%
//                   </p>
//                 ) : (
//                   <Button variant="contained" onClick={() => fetchQuiz(currentModule?.moduleId)}>
//                     Take Quiz
//                   </Button>
//                 )} */}


//                 {/* <div className="sidebar">
//                   <h3>Quiz Scores</h3>
//                   {modules.map((module) => (
//                     <p key={module.moduleId}>
//                       {module.title}:
//                       {userProgress.quizScores?.[module.moduleId]
//                         ? `${userProgress.quizScores[module.moduleId]}%`
//                         : "Not Attempted"}
//                     </p>
//                   ))}
//                 </div> */}

//               {/* </div> */}
//               {/* </div> */}
//             </div>
//           )
//         })}
//       </div>

//       {/* Main Content */}
//       <div
//     className={`w-full  h-[calc(100vh-10rem)] md:h-[calc(80vh-10rem)] md:h-4/5 md:w-3/4 md:bg-[#a43bcb] p-6 rounded-xl transition-all duration-300 ${
//       isSidebarOpen ? "opacity-0" : "opacity-100"
//     }`}
//   >
//         {modules.length > 0 && (
//           <>
//             <h1 className="text-2xl text-center text-white comic-sans-medium font-bold">{modules[currentModuleIndex].title}</h1>
//             <div
//               ref={lessonRef}
//               className="mt-4 p-4 bg-white opacity-80  shadow rounded-lg overflow-y-auto h-[calc(100vh-10rem)] md:h-[calc(80vh-10rem)] md:h-4/5"
//             >
//               <div className="text-black comic-sans-lesson mt-2">
//                 {modules[currentModuleIndex].lessons[currentLessonIndex].content.map((item, index) => (
//                   <p className="text-center comic-sans-lesson my-5" key={index}>{item.value}</p>
//                 ))}
//               </div>
//             </div>
//             <div className="flex justify-between my-4">
//               <button
//                 className="px-4 py-2 bg-gray-400 text-white rounded"
//                 disabled={currentLessonIndex === 0}
//                 onClick={() => setCurrentLessonIndex(currentLessonIndex - 1)}
//               >
//                 Previous
//               </button>
//               <button
//                 className={`px-4 py-2 rounded ${isNextEnabled ? "bg-blue-500 hover:bg-blue-600 text-white" : "bg-gray-300 cursor-not-allowed"
//                   }`}
//                 disabled={!isNextEnabled}
//                 onClick={handleNextLesson}
//               >
//                 Next
//               </button>
//               {/* <button
//                 className="px-4 py-2 bg-red-500 text-white rounded mt-4"
//                 onClick={resetAllLessons}
//               >
//                 Reset Progress
//               </button> */}

//             </div>
//           </>
//         )}
//       </div>

//       {/* Quiz Modal */}
//       {/* {showQuiz && quizData ? (
//         <Modal onClose={() => setShowQuiz(false)} sx={{ zIndex: 1300 }}>
//           <QuizComponent  quizData={quizData} onComplete={handleQuizCompletion} />
//         </Modal>
//        ): null} */}

//       <Modal open={showQuiz} onClose={() => setShowQuiz(false)} sx={{ zIndex: 1300 }}>
//         <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
//           {quizData ? (
//             <QuizComponent
//               moduleId={modules[currentModuleIndex].moduleId}
//               quizData={quizData}
//               onComplete={handleQuizCompletion}
//               modules={modules}
//               setCurrentModule={setCurrentModule}
//               setShowQuiz={setShowQuiz}
//             />
//           ) : (
//             <p className="text-white text-lg">Loading quiz...</p>
//           )}
//         </div>
//       </Modal>

//     </div>
//   );
// };

// export default CoursePage;
/// ----------------------------
// import { useState, useEffect, useRef } from "react";
// import { db, auth } from "../firebase";
// import { doc, getDoc, setDoc, collection, getDocs } from "firebase/firestore";
// import QuizComponent from "./QuizComponent";
// import CoachingNotice from "./CoachingNotice";
// import { useParams } from "react-router-dom";

// const CoursePage = () => {
//   const { courseId } = useParams();
//   const [modules, setModules] = useState([]);
//   const [currentModuleIndex, setCurrentModuleIndex] = useState(0);
//   const [currentLessonIndex, setCurrentLessonIndex] = useState(0);
//   const [completedLessons, setCompletedLessons] = useState({});
//   const [isNextEnabled, setIsNextEnabled] = useState(false);
//   const [quizScores, setQuizScores] = useState({});
//   const [coachingStatus, setCoachingStatus] = useState("Not started yet");
//   const [quizData, setQuizData] = useState(null);
//   const [isQuizModalOpen, setIsQuizModalOpen] = useState(false);

//   const lessonRef = useRef(null);
//   const user = auth.currentUser;

//   useEffect(() => {
//     const fetchModules = async () => {
//       try {
//         const courseRef = doc(db, "courses", courseId);
//         const courseSnap = await getDoc(courseRef);
//         console.log("courseSnap", courseSnap);
//         if (courseSnap.exists()) {
//           const courseData = courseSnap.data();
//           console.log("courseData modules", courseData.modules);

//           // Fetch full module details from the 'modules' collection
//           const modulePromises = courseData.modules.map(async (moduleId) => {
//             const moduleRef = doc(db, "modules", moduleId);
//             const moduleSnap = await getDoc(moduleRef);
//             console.log("moduleSnap", moduleSnap);
//             return moduleSnap.exists() ? moduleSnap.data() : null;
//           });
//           const modulesData = await Promise.all(modulePromises);
//           modulesData.forEach((mod, index) => {
//             if (!mod) {
//               console.error(`Module at index ${index} is missing!`);
//             }
//           });
//           setModules(modulesData.filter(Boolean)); // Remove nulls if any
//         } else {
//           console.log("No course found!");
//           setModules([]);
//         }
//       } catch (error) {
//         console.error("Error fetching modules:", error);
//         setModules([]);
//       }
//     };

//     fetchModules();
//   }, [courseId]);

//   useEffect(() => {
//     if (modules.length > 0) {
//       loadUserProgress();
//     }
//   }, [modules]);

//   useEffect(() => {
//     const fetchQuiz = async () => {
//       if (modules.length === 0) return;

//       try {
//         const quizzesRef = collection(db, "quizzes");
//         const querySnapshot = await getDocs(quizzesRef);

//         let foundQuiz = null;
//         querySnapshot.forEach((doc) => {
//           const quiz = doc.data();
//           if (quiz.moduleId === modules[currentModuleIndex].moduleId) {
//             foundQuiz = quiz;
//           }
//         });

//         setQuizData(foundQuiz || null);
//       } catch (error) {
//         console.error("Error fetching quiz:", error);
//         setQuizData(null);
//       }
//     };

//     fetchQuiz();
//   }, [currentModuleIndex, modules]);

//   const loadUserProgress = async () => {
//     if (!user) return;

//     const progressRef = doc(db, "userProgress", user.uid);
//     const progressSnap = await getDoc(progressRef);

//     if (progressSnap.exists()) {
//       const progressData = progressSnap.data();
//       setCompletedLessons(progressData.completedLessons || {});
//       setQuizScores(progressData.quizScores || {});
//       setCoachingStatus(progressData.coachingStatus || "Not started yet");

//       // Find the first uncompleted lesson
//       if (modules.length > 0) {
//         let moduleIndex = 0;
//         let lessonIndex = 0;
//         let foundUncompletedLesson = false;

//         for (let i = 0; i < modules.length; i++) {
//           for (let j = 0; j < modules[i].lessons.length; j++) {
//             const lesson = modules[i].lessons[j];
//             if (!progressData.completedLessons?.[lesson.lessonId]) {
//               moduleIndex = i;
//               lessonIndex = j;
//               foundUncompletedLesson = true;
//               break;
//             }
//           }
//           if (foundUncompletedLesson) break;
//         }

//         setCurrentModuleIndex(moduleIndex);
//         setCurrentLessonIndex(lessonIndex);
//       }
//     }
//   };

//   const markLessonComplete = async (lessonId) => {
//     if (!user) return;

//     try {
//       const updatedLessons = { ...completedLessons, [lessonId]: true };
//       setCompletedLessons(updatedLessons);

//       const progressRef = doc(db, "userProgress", user.uid);
//       await setDoc(progressRef, { completedLessons: updatedLessons }, { merge: true });

//       console.log(`Lesson ${lessonId} marked complete`);
//     } catch (error) {
//       console.error("Error marking lesson complete:", error);
//     }
//   };

//   const handleQuizCompletion = async (moduleId, score) => {
//     if (score >= 90) {
//       const updatedScores = { ...quizScores, [moduleId]: score };
//       setQuizScores(updatedScores);

//       const progressRef = doc(db, "userProgress", user.uid);
//       await setDoc(progressRef, { quizScores: updatedScores }, { merge: true });

//       setCurrentModuleIndex(currentModuleIndex + 1);
//       setCurrentLessonIndex(0);
//     } else {
//       alert("You must score at least 90% to proceed. Please retake the quiz.");
//     }
//   };

//   const handleScroll = () => {
//     if (lessonRef.current) {
//       const { scrollTop, scrollHeight, clientHeight } = lessonRef.current;
//       console.log(`Scroll Position: ${scrollTop} / ${scrollHeight - clientHeight}`);

//       if (scrollTop + clientHeight >= scrollHeight - 10) {
//         setIsNextEnabled(true);
//       }
//     }
//   };

//   const handleNextLesson = async () => {
//     const currentModule = modules[currentModuleIndex];

//     if (currentLessonIndex < currentModule.lessons.length - 1) {
//       await markLessonComplete(currentModule.lessons[currentLessonIndex].lessonId);
//       setCurrentLessonIndex((prevIndex) => prevIndex + 1);
//     } else if (quizScores[currentModule.moduleId] >= 90 && currentModuleIndex < modules.length - 1) {
//       // Move to the next module only if the quiz is passed
//       setCurrentModuleIndex((prevIndex) => prevIndex + 1);
//       setCurrentLessonIndex(0);
//     } else {
//       alert("Complete the quiz to proceed to the next module.");
//     }
//   };

//   return (
//     <div className="flex h-screen mt-40">
//       {/* Sidebar */}
//       {/* Sidebar - Course Modules */}
//       <div className="w-1/4 bg-[#84d4d8] rounded-2xl shadow-lg p-4 overflow-y-auto">
//         <h2 className="comic-sans-medium text-lg font-bold mb-4">Course Modules</h2>
//         {modules.length > 0 ? (
//           modules.map((module, moduleIndex) => (
//             <div key={module.moduleId || moduleIndex} className="mb-4">
//               <h3 className={`font-semibold comic-sans-small ${moduleIndex === currentModuleIndex ? "text-blue-600" : ""}`}>
//                 {module.title}
//               </h3>
//               {module.lessons && (
//                 <ul className="ml-4 mt-2 text-sm comic-sans-small text-gray-700">
//                   {module.lessons.map((lesson, lessonIndex) => {
//                     const lessonId = lesson.lessonId;
//                     const isCompleted = completedLessons[module.moduleId]?.[lessonId] || false;
//                     const isCurrent = lessonIndex === currentLessonIndex;
//                     const isUnlocked = isCompleted || lessonIndex <= currentLessonIndex;

//                     return (
//                       <li
//                         key={lessonId}
//                         className={`flex items-center p-1 rounded cursor-pointer 
//                                         ${isCurrent ? "bg-blue-200 font-bold" : ""}
//                                         ${isUnlocked ? "text-black" : "text-gray-400 cursor-not-allowed"}
//                                     `}
//                         onClick={() => isUnlocked && setCurrentLessonIndex(lessonIndex)}
//                       >
//                         {isCompleted ? <span className="mr-2 text-green-500"> ✔ </span> : "🔹"} {lesson.title}
//                       </li>
//                     );
//                   })}
//                 </ul>
//               )}
//             </div>
//           ))
//         ) : (
//           <p>Loading modules...</p>
//         )}
//       </div>

//       {/* <div className="w-1/4 bg-gray-100 p-4 overflow-y-auto">
//                 <h2 className="text-lg font-bold mb-4">Course Modules</h2>
//                 {modules && modules.length > 0 ? (
//                     modules.map((module, index) => (
//                         <div key={module.moduleId || index} className="mb-4">
//                             <h3 className={`font-semibold ${index === currentModuleIndex ? "text-blue-600" : ""}`}>
//                                 {module.title}
//                             </h3>
//                             {index === currentModuleIndex && module.lessons && (
//                                 <ul className="ml-4 mt-2 text-sm text-gray-700">
//                                     {module.lessons.map((lesson, idx) => (
//                                         <li key={lesson.lessonId || idx} className="flex items-center">
//                                             {idx === currentLessonIndex ? "➡️" : "🔹"} {lesson.title}
//                                             {completedLessons[lesson.lessonId] && <span className="ml-2 text-green-500">✔</span>}
//                                         </li>
//                                     ))}
//                                 </ul>
//                             )}
//                         </div>
//                     ))
//                 ) : (
//                     <p>Loading modules...</p>
//                 )}

//             </div> */}

//       {/* Main Content */}
//       <div className="w-3/4 p-6 overflow-y-auto">
//         {modules.length > 0 && (
//           <>
//             <h1 className="text-2xl font-bold">{modules[currentModuleIndex].title}</h1>
//             <div ref={lessonRef} className="mt-4 p-4 bg-white shadow rounded-lg overflow-y-auto h-[600px]" onScroll={handleScroll}>
//               <h2 className="text-xl font-semibold">{modules[currentModuleIndex].lessons[currentLessonIndex].title}</h2>
//               <p className="text-gray-700 mt-2">{modules[currentModuleIndex].lessons[currentLessonIndex].content}</p>
//             </div>

//             {/* Navigation Buttons */}
//             <div className="flex justify-between mt-4">
//               <button
//                 className="px-4 py-2 bg-gray-400 text-white rounded"
//                 disabled={currentLessonIndex === 0}
//                 onClick={() => setCurrentLessonIndex(currentLessonIndex - 1)}
//               >
//                 Previous
//               </button>
//               <button
//                 className={`px-4 py-2 rounded ${isNextEnabled ? "bg-blue-500 hover:bg-blue-600 text-white" : "bg-gray-300 cursor-not-allowed"}`}
//                 disabled={!isNextEnabled}
//                 onClick={
//                   // () => {
//                   // markLessonComplete(modules[currentModuleIndex].lessons[currentLessonIndex].lessonId);
//                   handleNextLesson
//                   // }
//                 }
//               >
//                 Next
//               </button>
//             </div>
//             <button 
//     onClick={() => setIsQuizModalOpen(true)} 
//     className="px-4 py-2 bg-blue-500 text-white rounded"
// >
//     Start Quiz
// </button>

//             {/* Key Concepts */}
//             {currentLessonIndex === modules[currentModuleIndex].lessons.length - 1 && (
//               <div className="mt-6 bg-gray-100 p-4 rounded">
//                 <h3 className="font-bold">Key Concepts Review</h3>
//                 <ul className="list-disc ml-6">
//                   {modules[currentModuleIndex].keyConcepts.map((concept, idx) => (
//                     <li key={idx}>{concept}</li>
//                   ))}
//                 </ul>
//               </div>
//             )}

//             {/* Quiz */}
//             {/* {currentLessonIndex === modules[currentModuleIndex].lessons.length - 1 && quizData && (
//               <QuizComponent
//                 moduleId={modules[currentModuleIndex].moduleId}
//                 quizData={quizData.questions}
//                 onComplete={(score) => handleQuizCompletion(modules[currentModuleIndex].moduleId, score)}
//               />
//             )} */}
//             <Modal isOpen={isQuizModalOpen} onClose={() => setIsQuizModalOpen(false)}>
//     <QuizComponent 
//         moduleId={modules[currentModuleIndex].moduleId} 
//         quizData={quizData?.questions || []} 
//         onComplete={(score) => {
//             handleQuizCompletion(modules[currentModuleIndex].moduleId, score);
//             setIsQuizModalOpen(false);
//         }}
//     />
// </Modal>



//             {/* Coaching Notice */}
//             {currentModuleIndex === 4 && coachingStatus !== "Completed" && (
//               <CoachingNotice userId={user?.uid} coachingStatus={coachingStatus} />
//             )}
//           </>
//         )}
//       </div>
//     </div>
//   );
// };

// const Modal = ({ isOpen, onClose, children }) => {
//   if (!isOpen) return null;

//   return (
//       <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center pt-20">
//           <div className="bg-[#fcce90] p-6 rounded-2xl shadow-2xl w-3/4 max-w-lg">
//               {/* <button onClick={onClose} className="absolute top-2 right-2 text-gray-600">✖</button> */}
//               {children}
//           </div>
//       </div>
//   );
// };

// export default CoursePage;