import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import filagotPic from "../assets/images/Filagot pic.jpg";
import drPic from "../assets/images/DrKal.jpg";
import icon from "../assets/images/icon.png";
import LandingNavbar from "./LandingNavbar.js";
import heroVid from "../assets/videos/heroVid.mp4";
import introVid from "../assets/videos/IntroVid.mp4";
import logo from "../assets/images/mentorable_logo.png";

const LandingPage = () => {
    const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);

    const handleSelection = (role) => {
        localStorage.setItem("userRole", role); // Store the role in localStorage
        // if (role === "student") {
        //   navigate("/student-home"); // Navigate to student portal
        // } else {
        //   navigate("/coach-home"); // Navigate to coach portal
        // }
    };

    return (
        <div className="min-h-screen flex items-center justify-center flex flex-col items-center min-h-screen relative overflow-hidden">
            <div className="area w-full top-0 left-0 h-full">
                <ul className="circles">
                    <li className="bg-[#84d4d8]"></li>
                    <li className="bg-[#a43bcb]"></li>
                    <li className="bg-[#e293ac]"></li>
                    <li className="bg-[#fb9160]"></li>
                    <li className="bg-[#84d4d8]"></li>
                    <li className="bg-[#fcce90]"></li>
                    <li className="bg-[#a43bcb]"></li>
                    <li className="bg-[#fb9160]"></li>
                    <li className="bg-[#e293ac]"></li>
                    <li className="bg-[#84d4d8]"></li>
                    <li className="bg-[#fcce90]"></li>
                    <li className="bg-[#e293ac]"></li>
                    <li className="bg-[#a43bcb]"></li>
                    <li className="bg-[#fb9160]"></li>
                </ul>
            </div>
            {/* <LandingNavbar /> */}
            <div className="w-full h-24 flex flex-row md:flex-row justify-between items-center justify-center mt-4">
                <img src={logo} alt="Parent" className="w-40 h-40 rounded-full mr-4" />
                <button
                    onClick={() => setIsModalOpen(true)}
                    className="bg-[#a43bcb] text-yellow-100 comic-sans-small opacity-100 rounded-full p-2"
                >
                    Contact Us
                </button>
            </div>
            <div className="flex flex-col md:flex-row justify-between justify-center items-center mx-3 my-5 py-10">
                <video className="w-full md:w-1/2 object-cover rounded-lg" autoPlay loop muted playsInline>
                    <source src={heroVid} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
                <div className="w-full m-3 md:w-full flex flex-col justify-center mx-auto px-5 mt-8 md:pl-10">
                    <h1 className="comic-sans-medium md:comic-sans-medium text-yellow-100 text-4xl md:text-6xl font-bold mb-5 md:mb-10 text-center md:text-left">
                        Mentorable: Empowering Future Entrepreneurs
                    </h1>
                    <p className="comic-sans-small md:comic-sans-medium text-yellow-100 text-lg md:text-xl text-center md:text-left">
                        Unleash Your Child's Potential: Entrepreneurship Education for a Brighter Future! < br/>
                        Equipping children aged 7-14 with essential business skills, financial literacy, and an entrepreneurial mindset.
                    </p>
                   
                </div>

            </div>

            <div className="flex flex-col justify-center items-center mx-12 space-y-8">
            <hr className="m-2 text-gray-700" />
                    <p className="bg-[#fb9160] p-8 rounded-2xl comic-sans-small md:comic-sans-medium text-white text-lg md:text-xl text-center md:text-center">
                    Mentorable is an online e-learning platform designed to teach children aged 7 to 15 about essential knowledge in entrepreneurship, business fundamentals, and financial management. Through engaging content, interactive modules, and exciting challenges, we inspire young learners to embrace innovation, creativity, and problem-solving.
                    </p>
                <h2 className="comic-sans-medium text-yellow-100 text-center">Please select a portal to proceed: </h2>
                <div className="flex flex-col gap-10 md:flex-row md:gap-20 my-10">
                    <Link
                        to="/student"
                        onClick={() => handleSelection("student")}
                        className="bg-[#84d4d8] text-blue-600 comic-sans-small font-semibold px-8 py-4 rounded-lg shadow-lg hover:bg-gray-200 transition duration-300 text-center"
                    >
                        Student Portal
                    </Link>
                    <Link
                        to="/coach/register"
                        onClick={() => handleSelection("coach")}
                        className="bg-pink-400 text-yellow-100 comic-sans-small font-semibold px-8 py-4 rounded-lg shadow-lg hover:bg-gray-200 hover:text-blue-600 transition duration-300 text-center"
                    >
                        Coach Portal
                    </Link>
                </div>
            </div>


            <section className="w-full bg-yellow-100 py-10 text-gray-700 text-center my-10">
                <div className="max-w-7xl mx-auto px-10 md:px-20 rounded-3xl">
                    <h2 className="comic-sans-hero text-4xl font-bold mb-5">The Problem We Solve</h2>
                    <p className="mb-4 comic-sans-small md:comic-sans-medium-bold">
                        <strong className="comic-sans-small md:comic-sans-medium-bold">The Challenge:</strong> Many traditional educational frameworks often lack the focus on nurturing creativity and entrepreneurial acumen vital for adapting to today's rapidly advancing world.
                    </p>
                    <p className="mb-4 comic-sans-small md:comic-sans-medium-bold">
                        <strong>The Gap:</strong> A profound discrepancy exists in equipping young learners with the fundamental understanding and pragmatic skills essential for igniting a passion for entrepreneurship and fostering financial literacy from an early developmental stage.
                    </p>
                </div>
            </section>


            <section className="w-full py-16 px-5 bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500 text-white text-center my-10 rounded-3xl shadow-lg relative overflow-hidden">

                <div className="absolute top-10 left-10 w-16 h-16 bg-[#fcce90] rounded-full animate-bounce"></div>
                <div className="absolute bottom-10 right-10 w-16 h-16 bg-[#FB9060] rounded-full animate-bounce"></div>
                <div className="absolute top-10 right-10 w-16 h-16 bg-[#84d4d8] rounded-full animate-bounce"></div>
                <div className="absolute bottom-10 left-10 w-16 h-16 bg-[#a43bcb] rounded-full animate-bounce"></div>

                <h2 className="comic-sans-medium md:comic-sans-large text-3xl md:text-4xl font-bold mt-10 mb-8 drop-shadow-lg animate-fade-in">✨ Our Solution ✨</h2>

                <ul className="text-left list-disc px-10 space-y-4 text-lg md:text-xl max-w-3xl mx-auto mb-12 animate-slide-up">
                    <li className="comic-sans-small md:comic-sans-medium-bold">📚 <strong>Engaging E-Learning:</strong> Interactive lessons on idea generation, marketing, finance, and problem-solving.</li>
                    <li className="comic-sans-small md:comic-sans-medium-bold">📱 <strong>Mobile-Friendly Content:</strong> Accessible nationwide, even with limited internet access.</li>
                    <li className="comic-sans-small md:comic-sans-medium-bold">🎓 <strong>A Structured Curriculum:</strong> Age-appropriate content aligned with the Ethiopian/African context.</li>
                    <li className="comic-sans-small md:comic-sans-medium-bold">🌍 <strong>A Virtual Educational Environment:</strong> Tools for user engagement, progress tracking, and seamless communication.</li>
                    <li className="comic-sans-small md:comic-sans-medium-bold">👨‍🏫 <strong>Expert Coaches:</strong> Providing coaching and mentorship.</li>
                    <li className="comic-sans-small md:comic-sans-medium-bold">🏆 <strong>Business Competitions:</strong> Culminating in a competition event where children can showcase their entrepreneurial ideas.</li>
                </ul>
            </section>


            <section className="flex flex-col md:flex-row justify-center items-center gap-10 my-10 text-gray-700">
                {/* Parent Enrollment Section */}
                <div className="flex flex-col justify-between w-full md:w-1/2 md:h-96 text-center bg-[#84d4d8] px-8 py-10 rounded-3xl shadow-lg">
                    <div>
                        <h2 className="comic-sans-medium md:comic-sans-large text-3xl font-bold mb-5">
                            Give Your Child the Edge!
                        </h2>
                        <p className="comic-sans-small md:comic-sans-medium text-4xl">
                            Enroll your child in Mentorable and unlock their potential. Provide them with the skills they need to thrive in a competitive global economy.
                        </p>
                    </div>
                    <div className="mt-8 md:mt-0">
                        <Link to="/student" className="bg-[#a43bcb] hover:bg-[#fcce90] hover:text-gray-700 text-yellow-100 comic-sans-small opacity-100 rounded-lg p-4 px-6 py-3 shadow-md transition duration-300">
                            Enroll Now
                        </Link>
                    </div>
                </div>

                {/* Coach Enrollment Section */}
                <div className="w-full md:w-1/2 md:h-96 text-center bg-[#fcce90] px-8 py-10 rounded-3xl shadow-lg">
                    <h2 className="comic-sans-medium md:comic-sans-large text-3xl font-bold mb-5">
                        Shape Young Entrepreneurs: Become a Mentorable Coach!
                    </h2>
                    <p className="comic-sans-small md:comic-sans-medium text-lg">Make a real difference in young lives and inspire the next generation of innovators.</p>
                    <p className="comic-sans-small md:comic-sans-medium text-lg mb-4">Earn competitive commission while coaching from anywhere.</p>
                    <p className="comic-sans-small md:comic-sans-medium text-lg">Receive comprehensive entrepreneurship training to enhance your own skills.</p>
                    <Link to="/coach/register" className="mt-6 inline-block bg-[#a43bcb] hover:bg-[#84d4d8] hover:text-gray-700 text-yellow-100 comic-sans-small opacity-100 rounded p-4 px-6 py-3 rounded-lg shadow-md transition duration-300">
                        Apply Now
                    </Link>
                </div>
            </section>

            <section className="w-full md:w-3/4 md:p-20 text-yellow-100 py-10 bg-gray-700 text-center my-10 rounded-full shadow-lg">
                <div className="flex flex-col items-center mb-4">
                    <h2 className="comic-sans-medium md:comic-sans-hero text-2xl font-bold text-center mb-4">Testimonial</h2>
                    <img src={filagotPic} alt="Parent" className="w-20 h-20 rounded-full mr-4" />
                </div>
                <p className="comic-sans-small md:comic-sans-medium text-yellow-100 mb-4">
                    After navigating the complexities of the business world and searching for my own path to success, I realized something important: the language of business doesn't have to be complicated. So, I decided to simplify those heavy business terms like "investment," "innovation," and "new market" into language that kids can easily grasp.
                </p>
                <p className="comic-sans-small md:comic-sans-medium text-yellow-100 mb-4">
                    That's when I came up with the idea to write an e-book specifically designed for children. My goal? To introduce them to the exciting world of business and entrepreneurship from an early age.
                </p>
                <p className="comic-sans-small md:comic-sans-medium text-yellow-100 mb-4">
                    In this e-book, I break down complex concepts into bite-sized pieces, making them fun and easy to understand. Through engaging stories and colorful illustrations, children can learn about the basics of business in a way that's both educational and entertaining.
                </p>
                <p className="comic-sans-small md:comic-sans-medium text-yellow-100 mb-4">
                    As an entrepreneur, I'm passionate about making a positive difference in the world. And what better way to do that than by inspiring the next generation of young minds to dream big and pursue their entrepreneurial aspirations?
                </p>
                <p className="comic-sans-small md:comic-sans-medium text-yellow-100 mb-4 font-semibold">- Filagot Tesfaye, Co-founder</p>
            </section>

            {/* Embedded Video */}


            <div className="flex flex-wrap md:flex-nowrap md:items-center my-6 mx-10">
                <div className="w-full md:w-1/2 pr-4 flex flex-col justify-start md:justify-center">
                    <div className="flex items-center mb-4">
                        <img src={icon} alt="Parent" className="w-12 h-12 rounded-full mr-4" />
                        <blockquote className="comic-sans-small md:comic-sans-hero italic text-yellow-100 text-4xl">“Mentorable has been transformative for my child! They're learning so much about business and are so excited about their own ideas.” <br /> - [Parent Name]</blockquote>
                    </div>
                    <div className="flex items-center mb-4">
                        <img src={drPic} alt="Dr. Kalkidan Tesfaye" className="w-12 h-12 rounded-full mr-4" />
                        <blockquote className="comic-sans-small md:comic-sans-hero italic text-yellow-100 text-4xl">“During middle childhood, significant cognitive changes take place. Just as physical growth—such as changes in weight, height, and teeth—occurs during this age, other aspects of their development also progress. This is a time when children channel their energy into creativity and productivity.” <br /> - Dr. Kalkidan Tesfaye, Pediatrician and Educator</blockquote>
                    </div>
                </div>
                <div className="w-full md:w-1/2 flex justify-center">
                    <video className="w-full md:w-1/2 h-[36rem] object-cover rounded-lg" autoPlay loop muted playsInline>
                        <source src={introVid} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>
            </div>

            <footer className="flex flex-col items-center justify-center mt-6 pt-4 border-t">
                <p className="comic-sans-small text-gray-700 text-center">Contact us: +(251) 911516196, info@mentorable.com</p>
                <p className="comic-sans-small text-gray-700 flex flex-col justify-center items-center text-center"> Follow us:
                    <a href="https://www.tiktok.com/@mentor_able?_t=8mkpkkyEI90&_r=1" className="text-gray-500 hover:text-gray-900">
                        <span className="[&>svg]:h-5 [&>svg]:w-5">
                            <svg className="w-4 h-4" xmlns="http://www.w3.org/2000/svg" fill="yellow" viewBox="0 0 500 600">
                                <path d="M448 209.9a210.1 210.1 0 0 1 -122.8-39.3V349.4A162.6 162.6 0 1 1 185 188.3V278.2a74.6 74.6 0 1 0 52.2 71.2V0l88 0a121.2 121.2 0 0 0 1.9 22.2h0A122.2 122.2 0 0 0 381 102.4a121.4 121.4 0 0 0 67 20.1z" />
                            </svg>
                        </span>
                    </a>
                </p>
                <p className="comic-sans-small text-gray-700 text-center">© {new Date().getFullYear()} All rights reserved.</p>
            </footer>

            {/* Contact Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-10 rounded-lg shadow-lg text-center">
            <h3 className="comic-sans-medium md:comic-sans-large text-[#a43bcb] text-xl font-bold mb-2">Contact Information</h3>
            <p className="comic-sans-small md:comic-sans-medium text-gray-700">Phone: +(251) 911516196</p>
            <p className="comic-sans-small md:comic-sans-medium text-gray-700">Email: info@mentorable.com</p>
            <button
              className="comic-sans-small mt-4 bg-red-500 text-white px-4 py-2 rounded-lg hover:bg-red-600"
              onClick={() => setIsModalOpen(false)}
            >
              Close
            </button>
          </div>
        </div>
      )}
        </div>
    );
};

export default LandingPage;