import React, { useState, useEffect } from "react";
import { FaBars } from "react-icons/fa";
import { NavLink } from "react-router-dom";

import logoImage from "../assets/images/logoAndText.png";

import { auth } from "../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";

const CoachNavbar = () => {
  const [nav, setNav] = useState(false);
  const [showBackground, setShowBackground] = useState(false);
  const [user, loading] = useAuthState(auth);
  const navigate = useNavigate();
  

  const TOP_OFFSET = 50;

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY >= TOP_OFFSET) {
        setShowBackground(true);
      } else {
        setShowBackground(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleNavClick = () => {
    setNav(!nav);
  };

  const handleLogOut = () => {
    auth.signOut();
    navigate("/student");
  };

  return (
    <div
      className={`flex justify-around w-full fixed z-10 p-5 ${
        showBackground ? "bg-[#F0CF2D] shadow-lg" : "bg-transparent"
      } transition-colors duration-300`}
    >
      <div className="container mx-auto flex justify-between items-center">
        {/* Logo */}
        <NavLink to="/">
          <img
            src={logoImage}
            alt="Logo"
            className=" h-[100px] w-[100px]  rounded-full"
          />
        </NavLink>

        {/* Desktop Links */}
        <ul className="hidden md:flex space-x-8">
          <div className="bg-[#F0CF2D] opacity-100 rounded-full">
            <li>
              <NavLink to="/coach" className="bubblegum-sans-link text-black">
                Dashboard
              </NavLink>
            </li>
          </div>
          <div className="bg-[#F0CF2D] opacity-100 rounded-full">
            <li>
              <NavLink to="/coach/profile" className="bubblegum-sans-link text-black">
                My Profile
              </NavLink>
            </li>
          </div>
          {/* <div className="bg-[#F0CF2D] opacity-100 rounded-full">
            <li>
              <NavLink to="/students" className="bubblegum-sans-link text-black">
                My Students
              </NavLink>
            </li>
          </div> */}
          {/* <div className="bg-[#F0CF2D] opacity-100 rounded-full">
            <li>
              <NavLink to="/invitations" className="bubblegum-sans-link text-black">
                Invitations
              </NavLink>
            </li>
          </div> */}
          {/* <div className="bg-[#F0CF2D] opacity-100 rounded-full">
            <li>
              <NavLink to="/reports" className="bubblegum-sans-link text-black">
                Reports
              </NavLink>
            </li>
          </div> */}
        </ul>

        {/* Action Buttons */}
        <div className="flex items-center space-x-4">
          {user ? (
            <button
              onClick={handleLogOut}
              className="btn outline-button border rounded-2xl text-white"
            >
              Log Out
            </button>
          ) : (
            <NavLink to="/login" className="btn outline-button border rounded-2xl text-white">
              Log In
            </NavLink>
          )}

          {/* Mobile Hamburger */}
          <div className="md:hidden">
            <FaBars
              size={20}
              className="text-black cursor-pointer"
              onClick={handleNavClick}
            />
          </div>
        </div>
      </div>

      {/* Mobile Dropdown Menu */}
      <div
        className={
          nav
            ? "overflow-y-hidden md:hidden ease-in duration-300 absolute right-0 w-1/2 top-0 h-auto bg-[#F0CF2D] text-black px-4 py-7 flex flex-col"
            : "absolute top-0 h-screen left-[-100%] ease-in duration-500 "
        }
      >
        <FaBars
          size={20}
          className="text-black cursor-pointer flex justify-right"
          onClick={handleNavClick}
        />
        <ul className="h-full w-full text-center pt-12">
          <li className="text-2xl py-8">
            <NavLink to="/">Home</NavLink>
          </li>
          <li className="text-2xl py-8">
            <NavLink to="/sessions">My Sessions</NavLink>
          </li>
          <li className="text-2xl py-8">
            <NavLink to="/students">My Students</NavLink>
          </li>
          <li className="text-2xl py-8">
            <NavLink to="/invitations">Invitations</NavLink>
          </li>
          <li className="text-2xl py-8">
            <NavLink to="/reports">Reports</NavLink>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default CoachNavbar;