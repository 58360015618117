import React, { useState } from "react";
import { auth, db } from "../../firebase";
import { signInWithEmailAndPassword, GoogleAuthProvider, signInWithCredential, sendPasswordResetEmail } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../assets/images/mentorable_logo.png";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";

const SignIn = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      console.log(user)
      // Fetch user data from Firestore
      const userRef = doc(db, "coaches", user.uid);
      const userSnap = await getDoc(userRef);
      if (userSnap.exists()) {
        const userData = userSnap.data();
        if (userData.profileCompleted) {
          navigate("/coach/"); // Profile complete → Go to home
        } else {
          navigate("/coach/complete-profile"); // Incomplete → Go to profile completion page
        }
      } else {
        setError("User data not found.");
      }
    } catch (err) {
      setError(err.message);
    }
  };

  const handleSuccess = async (response) => {
    console.log("Google Sign-In Success:", response);

    try {
      const googleIdToken = response.credential;
      const credential = GoogleAuthProvider.credential(googleIdToken);
      const userCredential = await signInWithCredential(auth, credential);
      const user = userCredential.user;

      console.log("Firebase login successful:", user);

      // Check if the user exists in the "coaches" collection
      const coachRef = doc(db, "coaches", user.uid);
      const coachSnap = await getDoc(coachRef);

      if (!coachSnap.exists()) {
        setError("Coach account not found. Please register first.");
        return;
      }

      // Coach exists, check profile completion status
      const coachData = coachSnap.data();
      if (coachData.profileCompleted) {
        navigate("/coach/");
      } else {
        navigate("/coach/complete-profile");
      }

    } catch (err) {
      console.error("Google Sign-In failed:", err);
      setError("Google Sign-In failed. Please try again.");
    }
  };

  const handleError = () => {
    console.error("Google Sign-In Error");
    setError("Google Sign-In was unsuccessful. Please try again.");
  };

  const handleForgotPassword = async () => {
    if (!email) {
      setError("Please enter your email first.");
      return;
    }

    try {
      await sendPasswordResetEmail(auth, email);
      setMessage("Password reset email sent! Check your inbox.");
      setError("");
    } catch (err) {
      setError("Error sending password reset email. Please try again.");
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center ">
      <div className="area w-full top-0 left-0 h-full">
        <ul className="circles">
          <li className="bg-[#84d4d8]"></li>
          <li className="bg-[#a43bcb]"></li>
          <li className="bg-[#e293ac]"></li>
          <li className="bg-[#fb9160]"></li>
          <li className="bg-[#84d4d8]"></li>
          <li className="bg-[#fcce90]"></li>
          <li className="bg-[#a43bcb]"></li>
          <li className="bg-[#fb9160]"></li>
          <li className="bg-[#e293ac]"></li>
          <li className="bg-[#84d4d8]"></li>
          <li className="bg-[#fcce90]"></li>
          <li className="bg-[#e293ac]"></li>
          <li className="bg-[#a43bcb]"></li>
          <li className="bg-[#fb9160]"></li>
        </ul>
      </div>
      <div className="bg-white/90  shadow-lg rounded-lg p-8 max-w-md w-full">
        
        <h2 className="comic-sans-medium text-2xl font-bold text-gray-800 mb-6 text-center">Coach Login</h2>
        {error && <p className="text-red-500 text-sm mb-4">{error}</p>}
        {message && <p className="text-green-500 text-sm mb-4">{message}</p>}
        <form onSubmit={handleLogin}>
          <div className="mb-4">
            <label htmlFor="email" className="comic-sans-small block text-gray-800 font-medium mb-1">
              Email
            </label>
            <input
              type="email"
              id="email"
              className="w-full bg-gray-100 border border-gray-300 rounded-md p-2 focus:outline-none focus:ring focus:ring-[#F0CF2D]"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="mb-4">
            <label htmlFor="password" className="comic-sans-small block text-gray-800 font-medium mb-1">
              Password
            </label>
            <input
              type="password"
              id="password"
              className="w-full bg-gray-100 border border-gray-300 rounded-md p-2 focus:outline-none focus:ring focus:ring-[#F0CF2D]"
              placeholder="Enter your password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <button
            type="submit"
            className="mb-4 comic-sans-small w-full bg-[#F0CF2D] text-gray-800 py-2 rounded-md hover:bg-[#E1BC28] font-semibold"
          >
            Log In
          </button>
        </form>
        <GoogleOAuthProvider clientId="762192583663-rf9tkpeu3u88c0slo4vgqglqcfsigq2u.apps.googleusercontent.com">
          <GoogleLogin

            onSuccess={handleSuccess}
            onError={handleError}
            useOneTap
          />
        </GoogleOAuthProvider>
        <button
          onClick={handleForgotPassword}
          className="mt-3 block w-full text-center text-gray-600 hover:underline hover:pointer hover:bg-white/90  border-none text-sm font-medium"
        >
          Forgot Password?
        </button>
        <p className="comic-sans-tiny text-gray-600 text-sm text-center mt-4">
          Haven't registered yet?{" "}
          <Link to="/coach/register" className="text-gray-800 hover:text-[#F0CF2D] hover:underline font-medium">
            Sign up here
          </Link>
        </p>
      </div>
    </div>
  );
};

export default SignIn;