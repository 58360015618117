import { createContext, useContext, useState, useEffect } from "react";
import { db } from "../../firebase";
import { collection, getDocs } from "firebase/firestore";
import bcrypt from "bcryptjs"

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    useEffect(() => {
        const storedAuth = localStorage.getItem("isAuthenticated");
        if (storedAuth === "true") {
            setIsAuthenticated(true);
        }
    }, []);

    const login = async (username, password) => {
        try {
            const querySnapshot = await getDocs(collection(db, "admin"));
            let isValid = false;
    
            querySnapshot.forEach((doc) => {
                const adminData = doc.data();
                if (adminData.username === username && bcrypt.compareSync(password, adminData.password)) {
                    isValid = true;
                }
            });
    
            if (isValid) {
                setIsAuthenticated(true);
                localStorage.setItem("isAuthenticated", "true");
                console.log("Admin logged in!")
                return true;
            } else {
                return false;
            }
        } catch (error) {
            console.error("Error fetching admin credentials:", error);
            return false;
        }
    };

    const logout = () => {
        setIsAuthenticated(false);
        localStorage.removeItem("isAuthenticated");
    };

    return (
        <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
