import React, { useState, useEffect } from "react";
import { auth, db } from "../firebase";
import { doc, getDoc } from "firebase/firestore";
import DetailsModal from "./DetailsModal"; // Import the DetailsModal component

const CoachHomepage = () => {
  const [coachStatus, setCoachStatus] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [coachName, setCoachName] = useState(null);
  useEffect(() => {
    const fetchCoachStatus = async () => {
      try {
        const user = auth.currentUser;
        if (user) {
          const coachRef = doc(db, "coaches", user.uid);
          // setCoachName(user.displayName);
          // console.log("COACH NAME:", coachName);
          const coachSnap = await getDoc(coachRef);
          if (coachSnap.exists()) {
            const coachData = coachSnap.data();
            setCoachStatus(coachData.status);
            console.log(coachSnap.data());
            setCoachName(coachData.name || user.displayName);
          } else {
            setCoachName(user.displayName);
          }
        }
      } catch (error) {
        console.error("Error fetching coach status:", error);
      }
      setIsLoading(false);
    };

    fetchCoachStatus();
    console.log(coachStatus);
  }, []);

  
  const upcomingSessions = [
    { title: "Math Tutoring", date: "Feb 4, 2025", time: "10:00 AM" },
    { title: "Science Workshop", date: "Feb 25, 2025", time: "2:00 PM" },
  ];

  const recentActivities = [
    { activity: "Reviewed Assignment 1", date: "Jan 22, 2025" },
    { activity: "Held a coaching session", date: "Jan 21, 2025" },
  ];

  const invitedStudentsCount = 15; // Number of students invited
  const bonusPerStudent = 10; // Bonus commission per student
  const totalBonus = invitedStudentsCount * bonusPerStudent;

  const [isModalOpen, setIsModalOpen] = useState(false); // State to control modal visibility
  const [inviteLink, setInviteLink] = useState(""); // State to store the generated invite link

  const generateInviteLink = () => {
    // Generate a unique link for the coach (e.g., using a coach's ID)
    const coachId = "coach123"; // Replace with actual coach ID or dynamic data
    const link = `${window.location.origin}/invite/${coachId}`;
    setInviteLink(link);
  };

  const handleCopyLink = () => {
    // Copy the invite link to clipboard
    navigator.clipboard.writeText(inviteLink);
    alert("Invite link copied to clipboard!");
  };

  if (isLoading) {
    return <p className="text-center text-gray-700">Loading...</p>;
  }

  if (coachStatus !== "Approved") {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="bg-white shadow-lg rounded-lg p-8 max-w-md w-full text-center">
          <h2 className="comic-sans-medium font-bold text-yellow-500 mb-4">Your Status: < br/> {coachStatus}</h2>
          <p className="comic-sans-small text-gray-700">Your account is awaiting admin approval. Please check back later.</p>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen flex flex-col items-center justify-center p-6 pt-[110px]">
      {/* Header */}
      <header className="mb-8 flex flex-col items-center">
        <h1 className="comic-sans-medium text-3xl font-bold text-yellow-100 bg-gray-700 p-8 rounded-2xl shadow-4xl">Welcome {coachName}!</h1>
        {/* <p className="text-gray-700">Here’s what’s happening today:</p> */}
      </header>
      <div className="bg-white shadow-lg rounded-lg p-8 max-w-md w-full text-center">
      <h2 className="comic-sans-medium font-bold text-green-500 mb-4">Your Status: < br/> {coachStatus}!</h2>
      <p className="comic-sans-small text-gray-700">Please wait for further contact regarding next steps.</p>
      </div>
      {/* Quick Stats */}
      {/* <section className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
        <div className="shadow-md rounded-lg p-6 text-center" style={{ background: "#fb9160", color: "white" }}>
          <h2 className="text-2xl font-bold">5</h2>
          <p>Total Sessions</p>
        </div>
        <div className="shadow-md rounded-lg p-6 text-center" style={{ background: "#84d4d8", color: "white" }}>
          <h2 className="text-2xl font-bold">20</h2>
          <p>Students Coached</p>
        </div>
        <div className="shadow-md rounded-lg p-6 text-center" style={{ background: "#a43bcb", color: "white" }}>
          <h2 className="text-2xl font-bold">18</h2>
          <p>Lessons Completed</p>
        </div>
      </section> */}

      {/* Invitation Tracker */}
      {/* <section className="mb-8">
        <h2 className="text-xl font-bold text-gray-900 mb-4">Your Invitation Tracker</h2>
        <div className="shadow-md rounded-lg p-6" style={{ background: "rgba(255, 255, 255, 0.9)" }}>
          <div className="flex justify-between items-center mb-4">
            <div>
              <h3 className="text-lg font-bold text-gray-900">
                Students Invited:{" "}
                <span className="text-[#fb9160]">{invitedStudentsCount}</span>
              </h3>
              <p className="text-gray-700">
                Bonus Earned:{" "}
                <span className="font-semibold text-[#84d4d8]">ETB {totalBonus}</span>
              </p>
            </div>
            <button
              className="px-4 py-2 text-white rounded-md"
              style={{ background: "#F0CF2D" }}
              onClick={() => {
                generateInviteLink(); // Generate the invite link when button is clicked
                setIsModalOpen(true); // Show the modal
              }}
            >
              Invite More Students
            </button>
          </div>
          <p className="text-sm text-gray-600">
            Earn ETB 100 for every student you invite! Share the link with your network and grow your impact while earning bonuses.
          </p>
        </div>
      </section> */}

      {/* Modal for invite link */}
      {/* {isModalOpen && (
        <DetailsModal onClose={() => setIsModalOpen(false)}>
          <h2 className="text-xl font-bold text-gray-900 mb-4">Invite Students</h2>
          <p className="text-gray-700 mb-4">Here is your unique invitation link:</p>
          <div className="flex flex-col items-center">
            <input
              type="text"
              value={inviteLink}
              readOnly
              className="w-full p-2 mb-4 border rounded-lg"
            />
            <button
              className="px-6 py-2 text-white rounded-md"
              style={{ background: "#84d4d8" }}
              onClick={handleCopyLink} // Copy the link when button is clicked
            >
              Copy Link
            </button>
          </div>
        </DetailsModal>
      )} */}

      {/* Upcoming Sessions */}
      {/* <section className="mb-8">
        <h2 className="text-xl font-bold text-gray-900 mb-4">Upcoming Sessions</h2>
        <div className="shadow-md rounded-lg p-6" style={{ background: "rgba(255, 255, 255, 0.9)" }}>
          {upcomingSessions.length > 0 ? (
            upcomingSessions.map((session, index) => (
              <div key={index} className="flex justify-between items-center border-b border-gray-300 py-3">
                <div>
                  <p className="font-medium text-gray-900">{session.title}</p>
                  <p className="text-sm text-gray-700">{session.date} - {session.time}</p>
                </div>
                <button className="px-4 py-2 rounded-md" style={{ background: "#F0CF2D" }}>
                  View
                </button>
              </div>
            ))
          ) : (
            <p className="text-gray-700">No upcoming sessions scheduled.</p>
          )}
        </div>
      </section> */}

      {/* Recent Activities */}
      {/* <section className="mb-8">
        <h2 className="text-xl font-bold text-gray-900 mb-4">Recent Activities</h2>
        <div className="shadow-md rounded-lg p-6" style={{ background: "rgba(255, 255, 255, 0.9)" }}>
          {recentActivities.length > 0 ? (
            recentActivities.map((activity, index) => (
              <div key={index} className="py-2">
                <p className="font-medium text-gray-900">{activity.activity}</p>
                <p className="text-sm text-gray-700">{activity.date}</p>
              </div>
            ))
          ) : (
            <p className="text-gray-700">No recent activities.</p>
          )}
        </div>
      </section> */}

      {/* Quick Actions */}
      {/* <section>
        <h2 className="text-xl font-bold text-gray-900 mb-4">Quick Actions</h2>
        <div className="flex flex-wrap gap-4">
          <button className="px-6 py-3 text-white rounded-md" style={{ background: "#fb9160" }}>
            Create New Session
          </button>
          <button className="px-6 py-3 text-white rounded-md" style={{ background: "#84d4d8" }}>
            Manage Students
          </button>
          <button className="px-6 py-3 text-white rounded-md" style={{ background: "#a43bcb" }}>
            View Reports
          </button>
        </div>
      </section> */}
    </div>
  );
};

export default CoachHomepage;
