import React, { useEffect, useState } from "react";
import { auth, db, storage } from "../../firebase";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { useNavigate } from "react-router-dom";
import { PencilIcon, UploadIcon } from "lucide-react";

const CoachProfile = () => {
    const [coach, setCoach] = useState(null);
    const [editing, setEditing] = useState(false);
    const [updatedInfo, setUpdatedInfo] = useState({});
    const navigate = useNavigate();
    const currentUser = auth.currentUser;

    useEffect(() => {
        if (!currentUser) return;

        const fetchCoach = async () => {
            const coachRef = doc(db, "coaches", currentUser.uid);
            const coachSnap = await getDoc(coachRef);
            if (coachSnap.exists()) {
                setCoach(coachSnap.data());
                setUpdatedInfo(coachSnap.data());
            }
        };

        fetchCoach();
    }, [currentUser]);

    const handleEdit = () => setEditing(true);
    const handleCancel = () => {
        setEditing(false);
        setUpdatedInfo(coach);
    };

    const handleSave = async () => {
        if (!currentUser) return;
        const coachRef = doc(db, "coaches", currentUser.uid);
        await updateDoc(coachRef, updatedInfo);
        setCoach(updatedInfo);
        setEditing(false);
    };

    const handleUpload = async (e, type) => {
        if (!currentUser) return;
        const file = e.target.files[0];
        if (!file) return;

        const fileRef = ref(storage, `coaches/${currentUser.uid}/${type}`);
        await uploadBytes(fileRef, file);
        const fileUrl = await getDownloadURL(fileRef);

        setUpdatedInfo(prev => ({ ...prev, [type]: fileUrl }));
    };

    if (!coach) return <p className="min-h-screen p-6 pt-[110px]">Loading...</p>;

    return (
        <div className="min-h-screen p-6 pt-[130px]">
            <div className="max-w-2xl mx-auto bg-[#84d4d8] border-4 border-[#fcce90] border-xl shadow-2xl p-6 rounded-lg">
                <h2 className="text-2xl comic-sans-medium font-bold text-center mb-4">Coach Profile</h2>
                <div className="flex flex-col items-center">
                    <img src={coach.photo || "https://via.placeholder.com/150"} 
                        alt="Profile" 
                        className="w-32 h-32 rounded-full border-4 border-gray-300 shadow-md" />
                    {editing && (
                        <input type="file" onChange={(e) => handleUpload(e, "photo")} className="mt-2 text-sm" />
                    )}
                </div>
                <div className="mt-6">
                    <label className="comic-sans-lesson block text-gray-700 font-semibold">Name</label>
                    <input type="text" value={updatedInfo.name} 
                        onChange={(e) => setUpdatedInfo({ ...updatedInfo, name: e.target.value })} 
                        className="w-full p-2 border rounded-md" readOnly={!editing} />
                </div>
                <div className="mt-4">
                    <label className="comic-sans-lesson block text-gray-700 font-semibold">Email</label>
                    <input type="email" value={coach.email} className="w-full p-2 border rounded-md" readOnly />
                </div>
                <div className="mt-4">
                    <label className="comic-sans-lesson block text-gray-700 font-semibold">Phone Number</label>
                    <input type="text" value={updatedInfo.phoneNumber} 
                        onChange={(e) => setUpdatedInfo({ ...updatedInfo, phoneNumber: e.target.value })} 
                        className="w-full p-2 border rounded-md" readOnly={!editing} />
                </div>
                <div className="mt-4">
                    <label className="comic-sans-lesson block text-gray-700 font-semibold">Degree & University</label>
                    <input type="text" value={updatedInfo.degree} 
                        onChange={(e) => setUpdatedInfo({ ...updatedInfo, degree: e.target.value })} 
                        className="w-full p-2 border rounded-md" readOnly={!editing} />
                </div>
                <div className="mt-4">
                    <label className="comic-sans-lesson block text-gray-700 font-semibold">CV</label>
                    <a href={coach.cv} target="_blank" rel="noopener noreferrer" className="text-blue-600">View CV</a>
                    {editing && (
                        <input type="file" onChange={(e) => handleUpload(e, "cv")} className="mt-2 text-sm" />
                    )}
                </div>
                {/* <div className="flex justify-between mt-6">
                    {editing ? (
                        <>
                            <button onClick={handleSave} className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600">
                                Save
                            </button>
                            <button onClick={handleCancel} className="bg-gray-400 text-white px-4 py-2 rounded-md hover:bg-gray-500">
                                Cancel
                            </button>
                        </>
                    ) : (
                        <button onClick={handleEdit} className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 flex items-center gap-2">
                            <PencilIcon className="w-5 h-5" /> Edit Profile
                        </button>
                    )}
                </div> */}
            </div>
        </div>
    );
};

export default CoachProfile;