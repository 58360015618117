// import { Card, CardContent, CardTitle } from "@/components/ui/card";
import { Card, CardContent, CardHeader } from "@mui/material";
import { useState } from "react";
import gurage  from "../assets/images/Gurage.jpeg"
import suq from "../assets/images/Suq.png"
import mefthe from "../assets/images/mefthe.jpeg"
import gulit from "../assets/images/Gulit.png"

const GamesPage = () => {
    const games = [
        { id: 1, name: "Gurage", image: gurage },
        { id: 2, name: "Suq", image: suq },
        { id: 3, name: "Mefthe", image: mefthe },
        { id: 4, name: "Gulit", image: gulit },
    ];

    return (
        <div className="p-6 md:p-10 mt-20 text-center">
            <h1 className="comic-sans-hero text-3xl font-bold text-yellow-100 mb-4 p-8">Available Games</h1>
            <p className="comic-sans-medium text-lg text-yellow-100 mb-6 p-8">
                You can find our <strong>Mentorable Games</strong> app on the App Store & Play Store!
            </p>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
                {games.map((game) => (
                    <Card key={game.id} className="bg-gray-700 rounded-4xl shadow-2xl hover:shadow-xl transition duration-300">
                        <CardContent className="bg-yellow-100 flex flex-col items-center p-4">
                            <img src={game.image} alt={game.name} className=" object-cover rounded-lg shadow-2xl mb-4" />
                            {/* <CardHeader className="text-xl font-semibold text-black">"{game.name}"</CardHeader> */}
                            <h1 className="comic-sans-medium text-xl font-semibold text-black">{game.name}</h1>
                        </CardContent>
                    </Card>
                ))}
            </div>
        </div>
    );
}

export default GamesPage;
